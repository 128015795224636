import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import logo from './logo.svg';
import { styled } from '../common/ThemedStyledComponent';
import { Link } from 'react-router-dom';

interface IProps {
  children: React.ReactNode;
}

const Layout = ({ children }: IProps) => {
  return (
    <LayoutContainer>
      <NavContainer>
        <Navbar.Brand href="/">
          <BrandContainer>
            <img src={logo} alt="logo" width="160" />
            <BetaFlag>alpha</BetaFlag>
          </BrandContainer>
        </Navbar.Brand>

        <Nav style={{ alignItems: 'center' }}>
          {/* <Nav.Link href="/#features">Features</Nav.Link> */}
          <Link to="/login">
            <LoginButton>Login</LoginButton>
          </Link>
        </Nav>
      </NavContainer>
      <MainContainer>{children}</MainContainer>
      <FooterContainer>
        <FooterLeft>
          <p>&copy; {new Date().getFullYear()} WingNotes</p>
        </FooterLeft>
        <FooterRight>
          {/* <StyledLink to="/about">About</StyledLink> */}
          <StyledLink to="/privacy-and-terms">Privacy and Terms</StyledLink>
        </FooterRight>
      </FooterContainer>
    </LayoutContainer>
  );
};

export { Layout };

const NavContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  margin: 6px auto 0px;
  max-width: 1100px;
  width: 100%;
  color: #000;
  a {
    color: #000;
    color: inherit;
    text-decoration: none;
  }
  a:visited {
    color: #000;
  }
`;

const MainContainer = styled.main`
  flex: 1 0 auto;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1100px;
  width: 100%;
  font-size: 13px;
  box-sizing: border-box;
  padding-bottom: 1px;
  a {
    color: #000;
    color: inherit;
    text-decoration: none;
  }
  a:visited {
    color: #000;
  }
  a:hover {
    border-bottom: 1px solid #000;
  }
`;

const FooterLeft = styled.div`
  display: flex;
`;

const FooterRight = styled.div`
  display: flex;
`;

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 1100px) {
    padding: 0px 1%;
  }
`;

const LoginButton = styled.div`
  cursor: pointer;
  display: flex;
  font-weight: 600;
  padding: 3px 16px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #d2d2d2;
  color: #5d5d5d;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(10, 16, 34, 0.2);
  transition: all 0.1s ease;
  &:hover {
    transform: scale(1.05);
  }
`;

const StyledLink = styled(Link)`
  margin-left: 12px;
`;

const BrandContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 18px;
  align-items: center;
`;

const BetaFlag = styled.div`
  display: flex;
  justify-content: center;
  height: 14px;
  align-items: center;
  border-radius: 4px;
  background-color: rgb(78, 66, 255);
  color: white;
  font-size: 9px;
  padding: 0px 3px;
  margin-left: 7px;
  margin-bottom: 10px;
`;
