import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { CodeEditor } from '.';
import { updateModuleInStore } from '../../../store/actions';
import { IAppState } from '../../../store/reducers';
import { IModuleAddress } from '../../../data-models';

interface IStateToProps {}
interface IDispatchToProps {
  updateModuleInStore(
    moduleAddress: IModuleAddress,
    updatedPayloadObj: any
  ): void;
}

interface IOwnProps {
  payloadObj: {
    code: string;
    settings: {
      enableLiveAutocompletion: boolean;
      fontSize: number;
      languageLabel: string;
      linterOn: boolean;
      readOnly: boolean;
      showLineNumbers: boolean;
      tabSize: number;
      theme: string;
      wrapCode: boolean;
    }
  };
  sectionsLength: number;
  moduleAddress: IModuleAddress;
}

export interface ICodeEditorProps
  extends IStateToProps,
    IDispatchToProps,
    IOwnProps {}

function mapStateToProps(state: IAppState) {
  return {};
}

function mapDispatchToProps(dispatch: Dispatch<any>): any {
  return {
    updateModuleInStore: bindActionCreators(updateModuleInStore, dispatch),
  };
}

export const CodeEditorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CodeEditor) as any;
