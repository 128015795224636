import React from 'react';
import { Layout } from './Layout';
import { styled } from '../common/ThemedStyledComponent';

interface IProps {}

const About = ({  }: IProps) => {
  return (
    <Layout>
      <HeroContainer>
        <h1>About</h1>
        <p>
          WingNotes is a new note-taker that helps you keep your notes organized and easy-to-read.
        </p>
        <h1>History</h1>
        <p>
          Born out of a pirvate side-project by{' '}
          <a href="https://brandontle.com/">Brandon Le</a>,
          WingNotes is now a free, public tool for anyone to use
          worldwide.
        </p>
      </HeroContainer>
    </Layout>
  );
};

export { About };

const HeroContainer = styled.div`
  max-width: 1100px;
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: 50px auto;
  p {
    font-size: 18px;
    a {
      color: blue;
      font-weight: 600;
      text-decoration: none;
      padding-bottom: 3px;
      border-bottom: 1px solid blue;
    }
    a:visited {
      color: blue;
    }
  }
`;
