//authentication checker (usher) to redirect already-signed-in users to /notes
//if not, only allow them to access non-auth page (ex. sign-in/sign-up page)
//ex. if already-signed-in user tries to access sign-in page, user is redirected to /notes

import React, { Component } from 'react';
import { IAppState } from '../../store/reducers';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

interface IHOCProps {
  auth: { isLoaded: boolean; uid: string };
}

//React.ComponentType<IComponentProps> is an alias for React.StatelessComponent<IComponentProps> | React.ClassComponent<IComponentProps>, allowing for sclass and functional components.
export const AuthUsherHOC =  <IComponentProps extends {}> (
  InputComponent: React.ComponentType<IComponentProps>
) => {
  //IComponentProps and IHOCProps combined via type intersection operator (&)
  class AuthUsher extends Component<IComponentProps & IHOCProps> {
    public render() {
      const { auth } = this.props;

      //if user is already authorized, redirect to notes
      //FUTURE: fix redirect from static to /notes
      if (auth.isLoaded && auth.uid) {
        return <Redirect to="/notes/redirect" />;
      } else {
        return <InputComponent {...this.props} />;
      }
    }
  }

  const mapStateToProps = (state: IAppState) => {
    return {
      auth: state.firebase.auth,
    };
  };

  return connect(mapStateToProps)(AuthUsher as any);
}
