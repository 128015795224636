import { connect } from 'react-redux';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { IAppState } from '../../../store/reducers/index';
import { Left } from './Left';
import { IModuleAddress, IModules } from '../../../data-models';
import { firestoreConnect } from 'react-redux-firebase';
import { insertSection, deleteSection } from '../../../store/actions';

interface IStateToProps {}
interface IDispatchToProps {
  insertSection(noteId?: string, sectionIndex?: number): void;
  deleteSection(noteId?: string, sectionIndex?: number): void;
}

interface IOwnProps {
  moduleAddress: IModuleAddress;
  leftModules: IModules;
  leftModulesOrder: string[];
  sectionsLength: number;
}

export interface ILeftProps
  extends IStateToProps,
    IDispatchToProps,
    IOwnProps {}

function mapStateToProps(
  state: IAppState,
  ownProps: IOwnProps
): IStateToProps & IOwnProps {
  const { moduleAddress, leftModules, leftModulesOrder, sectionsLength } = ownProps;

  return {
    leftModules,
    leftModulesOrder,
    moduleAddress,
    sectionsLength,
  };
}

//FUTURE: fix output's :any typing
function mapDispatchToProps(dispatch: Dispatch<any>): any {
  return {
    insertSection: bindActionCreators(insertSection, dispatch),
    deleteSection: bindActionCreators(deleteSection, dispatch),
  };
}

export const LeftContainer = compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: 'notes' }])
)(Left);
