import React, { Component } from 'react';
//@ts-ignore
import Board from 'react-trello';
import { styled } from '../../common/ThemedStyledComponent';
import { IModuleAddress } from '../../../data-models';

interface IState {}
interface IProps {
  data: any;
  moduleAddress: IModuleAddress;
  updateModuleInStore(
    moduleAddress: IModuleAddress,
    updatedPayloadObj: { listBoardData: {} }
  ): void;
}

class ListBoard extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const { data } = this.props;
    return (
      <StyledBoard
        data={data}
        onDataChange={(newData: any) => this.handleDataChange(newData)}
        draggable={true}
        editable={true}
        laneDraggable={false}
        addCardLink={<AddItemButton>Add item</AddItemButton>}
        style={{ backgroundColor: 'transparent', height: 'unset' }}
      />
    );
  }
  private handleDataChange = (newData: any) => {
    const { moduleAddress, updateModuleInStore } = this.props;
    const payloadObj = { listBoardData: newData };
    updateModuleInStore(moduleAddress, payloadObj);
  };
}

export { ListBoard };

const StyledBoard = styled(Board)`
  section {
    width: calc(100% - 8px);
  }
  div {
    width: 100%;
  }
  article {
    max-width: unset;
    min-width: unset;
  }
  span .kYtIM {
    white-space: normal;

  }
  .cZqlgI {
    background: #ffb93a;
  }
  .hntFuT {
    position: absolute;
    right: 5px;
    top: 10px;
  }
`;

const AddItemButton = styled.a`
  border-radius: 0 0 3px 3px;
  color: #838c91;
  display: block;
  padding: 5px 2px;
  position: absolute;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: pointer;
  bottom: 3px;
`;
