import React, { Component, Fragment } from 'react';
import { styled } from '../common/ThemedStyledComponent';

import { INoteProps as IProps } from './NoteContainer';
import { SectionsListContainer } from './SectionsList/SectionsListContainer';
import { ToolbarContainer } from './Toolbar/ToolbarContainer';
import ContentLoader from 'react-content-loader';
import { WarningBar } from './Toolbar/WarningBar';
import { Prompt } from 'react-router';
import ReactGA from 'react-ga';
import orderBy from 'lodash/orderBy';
import { SectionsList } from './SectionsList/SectionsList';
// @ts-ignore
import TextareaAutosize from 'react-textarea-autosize';

interface IState {}

class Note extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
    const {
      downloadNNotes,
      displayNote,
      urlNoteId,
      downloadedUserNotes,
    } = this.props;
    //if downloadedUserNotes is empty, download 10 most recent notes;
    if (Object.keys(downloadedUserNotes).length <= 1) {
      downloadNNotes(10);
    }
    displayNote(urlNoteId);
    this.props.history.listen(location => ReactGA.pageview(location.pathname));
  }

  public componentDidMount() {
    document.addEventListener('keydown', this.handleKeydownUp);
  }

  public componentDidUpdate() {
    const { redirect, downloadedUserNotes, history } = this.props;
    if (Object.keys(downloadedUserNotes).length !== 1 && redirect) {
      //if redirect is triggered, redirect user to the most recently available note
      //ex. redirects user upon first sign in, or invalid url
      const arrayOfNotes = Object.keys(downloadedUserNotes).map(noteId => {
        return downloadedUserNotes[noteId];
      });
      // descending order == newest notes first
      const orderedArrayOfNotes = orderBy(arrayOfNotes, 'updatedAt', 'desc');

      const mostRecentAvailableNoteId = orderedArrayOfNotes[0].noteId;
      history.push(`/notes/${mostRecentAvailableNoteId}`);
    }

    //to guard against exit with unsaved changes
    if (this.props.hasChanges) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }

  public componentWillUnmount() {
    const {
      hasChanges,
      noteExistsinDB,
      saveCurrentNote,
      urlNoteId,
    } = this.props;
    //if note exists in DB, save to Firestore.
    //handles case where user visits bad url link and gets redirected
    //otherwise, would auto-save a non existent, empty note
    if (!noteExistsinDB && hasChanges) {
      saveCurrentNote(urlNoteId);
    }
    document.onkeydown = null;
  }

  public render() {
    const { hasChanges, isCurrentNoteDownloaded } = this.props;

    // Only to generate knew starter notes
    //console.log('note', JSON.stringify(this.props.currentNote))

    if (!isCurrentNoteDownloaded) {
      return this.renderLoader();
    }

    return (
      <NoteView>
        <Prompt
          when={hasChanges}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        {/* <Note1200Container> */}
        {this.renderNote()}
        {/* </Note1200Container> */}
      </NoteView>
    );
  }

  private renderNote = () => {
    const { urlNoteId, currentNote, restoreNoteFromTrash, permDeleteNote, downloadedUserNotes, updateSectionOrder } = this.props;
    const note = downloadedUserNotes[urlNoteId];

    return currentNote ? (<Fragment>
        {currentNote.inTrash ? (
          <WarningBar
            noteId={currentNote.noteId as string}
            restoreNoteFromTrash={restoreNoteFromTrash}
            permDeleteNote={permDeleteNote}
          />
        ) : (
          <ToolbarContainer urlNoteId={this.props.urlNoteId} />
        )}

        <NoteHeader>
          <div style={{ display: 'flex', flex: '1.5 1' }}>
            {/* <Input value={currentNote.title} onChange={this.onChange} /> */}
            <StyledTextAreaAutosize
                value={currentNote.title}
                onChange={this.onChange}
                placeholder="Title"
              />
          </div>
          <div
            // to emulate a section's Right style so NoteHeader shrink/grows accordingly
            style={{
              maxWidth: '400px',
              flex: '1 1',
              paddingLeft: '31px',
              alignSelf: 'flex-end',
            }}
          />
        </NoteHeader>
        <SectionsList urlNoteId={urlNoteId} sections={note.sections} sectionsOrder={note.sectionsOrder} updateSectionOrder={updateSectionOrder}/>
        {/* <SectionsListContainer urlNoteId={urlNoteId} /> */}
      </Fragment>) : this.renderLoader();
    ;
  };

  private onChange = (event: any) => {
    //updateTitle
    const { updateTitleInStore } = this.props;
    updateTitleInStore(event.target.value);
  };

  private handleKeydownUp = (e: KeyboardEvent) => {
    e = e || event;
    if ((e.ctrlKey || e.metaKey) && e.which == 83) {
      e.preventDefault();
      if (this.props.hasChanges) {
        this.props.saveCurrentNote(this.props.urlNoteId);
      }
    }
  };

  private renderLoader = () => (
    <LoaderSectionStyled>
      <LeftSectionWrapper>
        <LeftLoader />
      </LeftSectionWrapper>
      <RightSectionWrapper>
        <RightLoader />
      </RightSectionWrapper>
    </LoaderSectionStyled>
  );
}

export { Note };

//styled components and loader

const NoteView = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 16px;
  /* padding: 0% 4% 2%; */
  padding-bottom: 2%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const NoteHeader = styled.div`
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  max-width: 1200px;
  max-height: fit-content;
  margin: 12vh auto 0;
  padding: 0 4% 0 calc(4% + 1.1em);
`;

const Input = styled.input`
  display: inline-block;
  border: 0px;
  flex: 1.5 1;
  box-shadow: none;
  background-color: transparent;
  font-size: 2em;
  font-weight: 600;
  max-height: fit-content;
  /* to compensate for note's left-side buttons */
  padding: 10px 0px 10px 4px;
  /* to emulate a section's Left style so NoteHeader shrink/grows accordingly */
  padding-right: 35px;
  flex: 1.5 1;
  max-width: 700px;
  box-sizing: border-box;
  /* to establish border width so that hover does not move Input */
  border: 1px solid transparent;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #f8f8f8;
  }
`;

const StyledTextAreaAutosize = styled(TextareaAutosize)`
  border: 0px;
  background-color: transparent;
  padding: 4px 0px 4px 4px;
  font-size: 2em;
  font-weight: 600;
  resize: none;
  overflow-wrap: break-word;
  width: 100%;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #f8f8f8;
  }
`;

const LoaderSectionStyled = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10%;
`;

const LeftSectionWrapper = styled.div`
  max-width: 50%;
  flex-basis: 50%;
  padding: 5%;
`;

const RightSectionWrapper = styled.div`
  max-width: 50%;
  flex-basis: 50%;
  padding: 5%;
`;

const LeftLoader = (props: any) => (
  <ContentLoader
    height={160}
    width={400}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="70" height="10" />
    <rect x="79" y="0" rx="3" ry="3" width="100" height="10" />
    <rect x="188.32" y="0" rx="3" ry="3" width="24.3" height="10" />
    <rect x="220.36" y="0" rx="3" ry="3" width="110.4" height="10" />
    <rect x="341.12" y="0" rx="3" ry="3" width="30" height="10" />
    <rect x="298.43" y="20" rx="3" ry="3" width="84" height="10" />
    <rect x="1" y="20" rx="3" ry="3" width="141.7" height="10" />
    <rect x="155" y="20" rx="3" ry="3" width="130" height="10" />
    <rect x="1.1" y="40" rx="3" ry="3" width="103.5" height="10" />
    <rect x="115" y="40" rx="3" ry="3" width="60" height="10" />
    <rect x="185" y="40" rx="3" ry="3" width="60" height="10" />
    <rect x="253" y="40" rx="3" ry="3" width="100" height="10" />
    <rect x="42.36" y="60" rx="3" ry="3" width="110.4" height="10" />
    <rect x="1" y="60" rx="3" ry="3" width="30" height="10" />
  </ContentLoader>
);

const RightLoader = (props: any) => (
  <ContentLoader
    height={160}
    width={400}
    speed={2}
    // dark color: #011627
    primaryColor="rgba(237,230,230,1)"
    secondaryColor="#fff"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="70" height="10" />
    <rect x="80" y="0" rx="3" ry="3" width="100" height="10" />
    <rect x="190" y="0" rx="3" ry="3" width="50" height="10" />
    <rect x="15" y="20" rx="3" ry="3" width="130" height="10" />
    <rect x="155" y="20" rx="3" ry="3" width="46" height="10" />
    <rect x="210" y="20" rx="3" ry="3" width="149.5" height="10" />
    <rect x="15" y="40" rx="3" ry="3" width="90" height="10" />
    <rect x="115" y="40" rx="3" ry="3" width="60" height="10" />
    <rect x="185" y="40" rx="3" ry="3" width="84.6" height="10" />
    <rect x="0" y="60" rx="3" ry="3" width="30" height="10" />
  </ContentLoader>
);

