import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import * as Icon from 'react-feather';
import { IModuleAddress } from '../../data-models';
import { styled } from '../common/ThemedStyledComponent';

interface IState {}
interface IProps {
  insertModule(
    moduleAddress: IModuleAddress,
    moduleType: string,
    moduleIndex: number
  ): void;
  moduleAddress: IModuleAddress;
  moduleIndex: number;
}

class InsertModuleButton extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <StyledWrapper>
        {/*
        //@ts-ignore */}
        <Dropdown
          id="input-group-dropdown-2"
          variant="transparent"
          onSelect={(eventKey: string) => {
            this.handleDropdownSelect(eventKey);
          }}
        >
          <Dropdown.Toggle
            variant="outline-secondary"
            id="dropdown-basic"
            style={{ padding: '0px', border: 'none', lineHeight: '0px' }}
          >
            <Icon.PlusSquare size={20} color="#848484" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="code">Code</Dropdown.Item>
            <Dropdown.Item eventKey="Preview">Link Preview</Dropdown.Item>
            <Dropdown.Item eventKey="Image">Image</Dropdown.Item>
            <Dropdown.Item eventKey="Audio">Audio</Dropdown.Item>
            <Dropdown.Item eventKey="Video">Video</Dropdown.Item>
            {/* <Dropdown.Item eventKey="List Board">List Board</Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
        <div style={{ width: '20px', marginTop: '4px', marginLeft: '4px' }}>
          <hr style={{ borderStyle: 'solid', borderColor: '#eae9e9' }} />
        </div>
      </StyledWrapper>
    );
  }
  private handleDropdownSelect = (moduleType: string) => {
    const { insertModule, moduleIndex, moduleAddress } = this.props;
    insertModule(moduleAddress, moduleType, moduleIndex);
  };
}

export { InsertModuleButton };

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  opacity: 0;
  transition: all 0.4s;
  margin-top: -2px;
  margin-left: -25px;
  &:hover {
    opacity: 1;
    transition: all 0.4s;
  }
`;
