import {
  INotesObj,
  INotesLocalStore,
  ICurrentNoteStatus,
} from '../store/reducers';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { welcomeNote } from './welcome';
import { exampleNote1 } from './exampleNote1';
import { exampleNote2 } from './exampleNote2';
import { algorithmsExample } from './algorithmsExample';
import { homeNote } from './homeNote';
import { defaultNewNote } from './defaultNewNote';
import { meetingExample } from './meetingExample';
import { INote, IUtility } from '../data-models';
import { blankCodeEditor } from './newBlankCodeEditor';
import { blankSection } from './newBlankSection';

const firestore = firebase.firestore;

export const createNoteTemplate = (ownerId: string, type?: string): INote => {
  let newNote;
  switch (type) {
    case 'welcome':
      newNote = Object.assign({}, welcomeNote);
      break;
    // case 'example1':
    //   newNote = Object.assign({}, exampleNote1);
    //   break;
    case 'example2':
      newNote = Object.assign({}, exampleNote2);
      break;
    case 'algorithmsExample':
      newNote = Object.assign({}, algorithmsExample);
      break;
    case 'meetingExample':
      newNote = Object.assign({}, meetingExample);
      break;
    case 'home':
      newNote = Object.assign({}, homeNote);
      break;
    default:
      newNote = Object.assign({}, defaultNewNote);
  }
  if (type !== 'home') {
    newNote.createdAt = firestore.Timestamp.now();
    newNote.updatedAt = firestore.Timestamp.now();
  }
  newNote.default = false;
  newNote.ownerIds[0] = ownerId;
  return Object.assign({}, newNote);
};

export const createBlankSection = () => {
  let newBlankSection = Object.assign({}, blankSection);
  newBlankSection.id = Math.random()
    .toString(36)
    .substr(2, 9);
  return newBlankSection;
};

export const createBlankCodeEditor = () => {
  let newCodeEditor = Object.assign({}, blankCodeEditor);
  newCodeEditor.id = Math.random()
    .toString(36)
    .substr(2, 9);
  return newCodeEditor;
};

const currentNoteStatus: ICurrentNoteStatus = {
  hasChanges: false,
  isDownloaded: false,
  noteId: '',
  noteExistsInDB: false,
};

const homeDemo = createNoteTemplate('id', 'home');

export const defaultNotesReducer: INotesLocalStore = {
  redirect: false,
  downloadedUserNotes: { homeDemo },
  defaultNotes: { homeDemo },
  currentNoteStatus,
};

export const defaultUtilityReducer: IUtility = {
  sidebarCategory: 'All',
  sidebarOrderLabel: 'updatedAt',
  sidebarAscOrDesc: 'desc',
};
