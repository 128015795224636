import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import * as Icon from 'react-feather';
import { IModuleAddress } from '../../data-models';
import { styled } from '../common/ThemedStyledComponent';
import Button from 'react-bootstrap/Button';

interface IProps {
  onClick(e: any, first?: boolean): void;
  first?: boolean;
  moduleAddress: IModuleAddress;
}

const InsertSectionButton = (props: IProps) => {
  return (
    <FullWidthContainer className="insertSectionButton">
      <StyledInsertButtonWrapper>
        <Button
          data-testid='insert-section-button'
          //@ts-ignore
          variant="transparent"
          onClick={e => props.onClick(e, props.first)}
        >
          <Icon.PlusCircle size={20} color="#848484" />
        </Button>
        {/* <div style={{ width: '20px', marginTop: '4px', marginLeft: '4px' }}>
          <hr style={{ borderStyle: 'solid', borderColor: '#eae9e9' }} />
        </div> */}
      </StyledInsertButtonWrapper>
      <Max1200pxContainer />
    </FullWidthContainer>
  );
};

export { InsertSectionButton };

const StyledInsertButtonWrapper = styled.div`
  display: flex;
  position: relative;
  right: 1.1em;
`;

const FullWidthContainer = styled.div`
  width: 100%;
  max-height: fit-content;
  display: flex;
  flex: 1 0 auto;
  transition: all 0.4s;
  padding: 0px 4%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  opacity: 0;
  &:hover {
    opacity: 1;
    transition: all 0.4s;
    background: #fbfbfb;
  }
`;

const Max1200pxContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 40px;
`;
