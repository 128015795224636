import React, { Component } from 'react';
import { debounce } from 'lodash';

import { ICodeEditorProps as IProps } from './codeEditorContainer';
import { styled } from '../../common/ThemedStyledComponent';
import Dropdown from 'react-bootstrap/Dropdown';
import producer from 'immer';

//ace editor
import AceEditor from 'react-ace';
import brace from 'brace';
import 'brace/mode/c_cpp';
import 'brace/mode/css';
import 'brace/mode/erlang';
import 'brace/mode/golang';
import 'brace/mode/graphqlschema';
import 'brace/mode/handlebars';
import 'brace/mode/haskell';
import 'brace/mode/html';
import 'brace/mode/java';
import 'brace/mode/javascript';
import 'brace/mode/json';
import 'brace/mode/jsx';
import 'brace/mode/markdown';
import 'brace/mode/objectivec';
import 'brace/mode/ocaml';
import 'brace/mode/php';
import 'brace/mode/python';
import 'brace/mode/ruby';
import 'brace/mode/sass';
import 'brace/mode/scss';
import 'brace/mode/sql';
import 'brace/mode/swift';
import 'brace/mode/typescript';
import 'brace/theme/katzenmilch';

interface IState {
  code: string;
  languageLabel: string;
  wrapCode: boolean;
}

class CodeEditor extends Component<IProps, IState> {
  public updateModuleInStore: any;
  constructor(props: IProps) {
    super(props);
    // included legacy variable 'language'
    const { code: intialCode, settings } = this.props.payloadObj;
    const { languageLabel, wrapCode } = settings;
    this.state = {
      code: intialCode,
      languageLabel: languageLabel || 'Javascript',
      wrapCode: wrapCode === undefined ? true : wrapCode,
    };
    //debounce updateModuleInStore function to .25seconds
    this.updateModuleInStore = debounce(this.props.updateModuleInStore, 250);
  }

  public componentDidUpdate(prevProps: IProps) {
    //update component state if code has changed (ex: when changing to new note)
    //Need better way to trigger updates when payloadObj is changed (comparing payloadObjs causes maximum-depth error)
    const { code: prevCode, settings: prevSettings } = prevProps.payloadObj;
    const { code, settings } = this.props.payloadObj;
    if (
      prevCode !== code ||
      prevSettings.languageLabel !== settings.languageLabel
    ) {
      this.setState({
        code,
        languageLabel: settings.languageLabel,
      });
    }
  }

  public render() {
    const { code, languageLabel } = this.state;
    return (
      <StyledView>
        {/*
        //@ts-ignore */}
        <StyledAceEditor
          //https://github.com/securingsincity/react-ace/blob/master/docs/Ace.md
          mode={languages[languageLabel]}
          //@ts-ignore
          theme="katzenmilch"
          name="CodeEditor"
          //maxLines, width, and height allows container to fit to content
          maxLines={Infinity}
          width="unset"
          height="unset"
          //adds side padding to editor (excluding gutter and top)
          //@ts-ignore
          onLoad={editor => {
            //@ts-ignore
            editor.renderer.setPadding(16);
          }}
          onChange={this.onValueChange}
          fontSize={14}
          showPrintMargin={false}
          s
          showGutter={false}
          focus={true}
          highlightActiveLine={false}
          value={code}
          wrapEnabled={this.props.payloadObj.settings.wrapCode}
          readOnly={false}
          blockScrolling="Infinity"
          //https://github.com/ajaxorg/ace/wiki/Configuring-Ace
          setOptions={{
            // enableBasicAutocompletion: false,
            // enableLiveAutocompletion: false,
            // enableSnippets: false,
            showLineNumbers: false,
            tabSize: 2,
            //useWorker == linter/code annotations
            //FUTURE: enable when can restyle
            useWorker: false,
          }}
          style={{
            lineHeight: '22px',
            color: 'rgb(74, 74, 74)',
            minHeight: '70px',
            backgroundColor: 'transparent',
          }}
          editorProps={{
            $blockScrolling: Infinity
          }}
        />

        <StyledLanguageDropdown>
          <Dropdown.Toggle
            // @ts-ignore
            variant="transparent"
          >
            <span style={{ color: 'rgba(0,0,0,.5)', fontSize: '12px' }}>
              {this.state.languageLabel}
            </span>
          </Dropdown.Toggle>
          {/*
          //@ts-ignore: Dropdown.Menu typings does nto include alignRight */}
          <Dropdown.Menu alignRight>
            {Object.keys(languages).map((languageLabel: string) => {
              return (
                <Dropdown.Item
                  key={languageLabel}
                  onClick={() => {
                    this.handleLanguageChange(languageLabel);
                  }}
                >
                  {languageLabel}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </StyledLanguageDropdown>
      </StyledView>
    );
  }

  private onValueChange = (updatedCode: string) => {
    const currentCode = this.state.code;
    // 1. update component's local state
    this.setState({
      code: updatedCode,
    });

    // 2. update local storage's state only if content (not selection) has changed
    if (currentCode !== updatedCode) {
      const { moduleAddress, payloadObj } = this.props;
      const updatedPayload = producer(payloadObj, draft => {
        draft.code = updatedCode;
      });
      this.updateModuleInStore(moduleAddress, updatedPayload);
    }
  };

  private handleLanguageChange = (updatedLanguage: string) => {
    this.setState({ languageLabel: updatedLanguage });
    const { moduleAddress, payloadObj } = this.props;
    const updatedPayload = producer(payloadObj, draft => {
      draft.settings.languageLabel = updatedLanguage;
    });
    this.updateModuleInStore(moduleAddress, updatedPayload);
  };
}

export { CodeEditor };

const StyledView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: rgba(237, 230, 230, 1);
  padding: 12px 0px;
`;

const StyledLanguageDropdown = styled(Dropdown)`
  display: flex;
  align-items: center;
  width: fit-content;
  align-self: flex-end;
  transition: all 0.4s;
  bottom: -10px;
  right: 8px;
  .dropdown-menu {
    height: 250px;
    overflow-y: scroll;
    z-index: 3;
  }
`;

const StyledAceEditor = styled(AceEditor)`
  display: flex;
  flex: 1 0;
  box-sizing: border-box;
  font-family: 'Dank Mono', 'Fira Code', monospace;
`;

const languages: { [key: string]: string } = {
  'C/C++': 'c_cpp',
  CSS: 'css',
  Erlang: 'erlang',
  Go: 'golang',
  GraphQL: 'graphqlschema',
  Handlebars: 'handlebars',
  Haskell: 'haskell',
  HTML: 'html',
  Java: 'java',
  Javascript: 'javascript',
  JSON: 'json',
  JSX: 'jsx',
  Markdown: 'markdown',
  'Objective-C': 'objectivec',
  OCaml: 'ocaml',
  PHP: 'php',
  Python: 'python',
  Ruby: 'ruby',
  Rust: 'rust',
  Sass: 'sass',
  SCSS: 'scss',
  SQL: 'sql',
  Swift: 'swift',
  Typescript: 'typescript',
};
