import React, { Component } from 'react';
import { styled } from '../../common/ThemedStyledComponent';
import producer from 'immer';

import { ILeftProps as IProps } from './LeftContainer';
import { RichEditorContainer } from '../../contentModules/MarkdownEditor/RichEditorContainer';

interface IState {}

class Left extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <LeftView>
          <ModuleView>{this.renderLeftContent()}</ModuleView>
          {this.props.children}
      </LeftView>
    );
  }
  private renderLeftContent() {
    //map over leftContent array and return the MarkdownEditor (ie. the current functional example) with the markdown content
    const { leftModules, leftModulesOrder, sectionsLength } = this.props;

    return (
      leftModulesOrder &&
      leftModulesOrder.map((leftModulesId, index) => {
        //use immer to create new immutable moduleAddress with leftIndex injected
        const moduleAddress = producer(this.props.moduleAddress, draft => {
          draft.leftModulesId = leftModulesId;
        });
        //conditional in case for other types of content in the future
        if (leftModules[leftModulesId].type === 'markdown') {
          return (
            // <SlateEditorContainer
            // key={index}
            // payloadObj={leftContentObj.payloadObj}
            // moduleAddress={moduleAddress}
            // />

            // <MarkdownEditorContainer
            //   key={index}
            //   payloadObj={leftModules[leftModulesId].payloadObj}
            //   moduleAddress={moduleAddress}
            //   sectionsLength={sectionsLength}
            // />

            <RichEditorContainer
              key={index}
              payloadObj={leftModules[leftModulesId].payloadObj}
              moduleAddress={moduleAddress}
              sectionsLength={sectionsLength}
            />
          );
        }
      })
    );
  }
}

export { Left };

//styled components
const LeftView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.5 1 700px;
  width: 100%;
  padding-right: 36px;
  justify-content: space-between;
  border-right: 2px solid rgba(0, 0, 0, 0.08);
  @media (max-width: 769px) {
    padding-right: unset;
    border-right: unset;
    flex: 1.5 1 auto;
  }
`;

const ModuleView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 700px;
  margin-bottom: 15;
  box-sizing: border-box;
`;
