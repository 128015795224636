import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { INote } from '../data-models';

const firestore = firebase.firestore;

export const meetingExample: INote = {
  noteId: 'meetingExample',
  ownerIds: [''],
  title: 'Meeting Notes Example',
  inTrash: false,
  default: true,
  createdAt: firestore.Timestamp.now(),
  updatedAt: firestore.Timestamp.now(),
  sectionsOrder: ['section-1', 'section-2', 'section-3'],
  sections: {
    'section-1': {
      id: 'section-1',
      leftModulesOrder: ['module-1'],
      rightModulesOrder: ['module-1'],
      leftModules: {
        'module-1': {
          id: 'module-1',
          moduleAddress: { leftModulesId: '', noteId: '', sectionId: '' },
          payloadObj: {
            markdown:
              "### Summary\n- Promo landing pages high bounce rate due to mobile bug -> Taylor\n- Need to prepare for Europe launch at end of month -> Me\n- We're switching to Mixpanel -> Alex",
          },
          type: 'markdown',
        },
      },
      rightModules: {
        'module-1': {
          id: 'module-1',
          moduleAddress: { noteId: '', rightModulesId: '', sectionId: '' },
          payloadObj: {
            listBoardData: {
              lanes: [
                {
                  cards: [
                    {
                      id: 'f1e91770-3c61-11e9-b093-bd3926e36128',
                      laneId: 'lane1',
                      title: 'Taylor: fix promo landing pages on mobile',
                    },
                    {
                      id: '89bb8b80-3c5f-11e9-b093-bd3926e36128',
                      laneId: 'lane1',
                      title:
                        'Decide on first European countries to launch in\n\n',
                    },
                    {
                      description: '',
                      id: '9c9f3530-3c5f-11e9-b093-bd3926e36128',
                      laneId: 'lane1',
                      title: 'Design AB test landing pages for Europe\n\n',
                    },
                    {
                      description: 'SEO and inbound marketing\n\n',
                      id: '28120f70-3c60-11e9-b093-bd3926e36128',
                      label: 'High',
                      laneId: 'lane1',
                      title: 'Message Mike about growth strategy\n',
                    },
                    {
                      description: 'Due: Mar 31, 2019',
                      id: '76143c50-3c62-11e9-b093-bd3926e36128',
                      laneId: 'lane1',
                      title: 'Alex to lead switch from Amplitude to Mixpanel\n',
                    },
                  ],
                  currentPage: 1,
                  id: 'lane1',
                  label: '',
                  title: 'List Board',
                },
              ],
            },
          },
          type: 'List Board',
        },
      },
    },
    'section-2': {
      id: 'section-2',
      leftModulesOrder: ['module-1'],
      rightModulesOrder: [],
      leftModules: {
        'module-1': {
          id: 'module-1',
          moduleAddress: { leftModulesId: '', noteId: '', sectionId: '' },
          payloadObj: {
            markdown:
              '### Quick Updates\n- Q4 results were good, revenue up 5% from expected\n- DAU is now 40k, and DAU/MAU ratio is 20%\n- Homepage bounce rate is 55%, but promotional landing page bounce rate is 95%',
            rawContentState: {
              blocks: [
                {
                  data: {},
                  depth: 0,
                  entityRanges: [],
                  inlineStyleRanges: [],
                  key: '2mnpe',
                  text: 'Quick Updates',
                  type: 'header-three',
                },
                {
                  data: {},
                  depth: 0,
                  entityRanges: [],
                  inlineStyleRanges: [],
                  key: '6vhtl',
                  text: 'Q4 results were good, revenue up 5% from expected',
                  type: 'unordered-list-item',
                },
                {
                  data: {},
                  depth: 0,
                  entityRanges: [],
                  inlineStyleRanges: [],
                  key: 'b2d4n',
                  text: 'DAU is now 40k, and DAU/MAU ratio is 20%',
                  type: 'unordered-list-item',
                },
                {
                  data: {},
                  depth: 0,
                  entityRanges: [],
                  inlineStyleRanges: [],
                  key: '8r4f7',
                  text:
                    'Homepage bounce rate is 55%, but promotional landing page bounce rate is 95%',
                  type: 'unordered-list-item',
                },
              ],
              entityMap: {},
            },
          },
          type: 'markdown',
        },
      },
      rightModules: {},
    },
    'section-3': {
      id: 'section-3',
      leftModulesOrder: ['module-1'],
      rightModulesOrder: ['module-1', 'module-2'],
      leftModules: {
        'module-1': {
          id: 'module-1',
          moduleAddress: { leftModulesId: '', noteId: '', sectionId: '' },
          payloadObj: {
            markdown:
              '### Notes\n- Bounce rate on promotional landing page too high, why?\n- Should we revise our marketing around value proposition? \n- Turns out promo pages show up blank on mobile\n- Taylor will lead promo page redesigns\n    - Number one user complaint was slow page loads\n    - Need to refactor codebase for speed optimization, check link for ideas ->\n- We should use Mixpanel instead of Amplitiude: more features and support\n- Europe launch will run at the end of the month. \n- Need to prepare AB testing landing pages for different European countries\n- England for sure; what other countries? Undecided..',
          },
          type: 'markdown',
        },
      },
      rightModules: {
        'module-1': {
          id: 'module-1',
          moduleAddress: { noteId: '', rightModulesId: '', sectionId: '' },
          payloadObj: {
            link:
              'https://redfin.engineering/how-we-improved-our-android-app-cold-start-time-by-28-a722e231314a',
            linkType: 'Preview',
          },
          type: 'link',
        },
        'module-2': {
          id: 'module-2',
          moduleAddress: { noteId: '', rightModulesId: '', sectionId: '' },
          payloadObj: {
            link:
              'https://medium.com/@joomiguelcunha/react-performance-tips-5fa199a450b2',
            linkType: 'Preview',
          },
          type: 'link',
        },
      },
    },
  },
};
