import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Main } from './components/views/Main';
import Login from './components/auth/Login';
import { AuthGuardHOC } from './components/hoc/authGuard';
import { AuthUsherHOC } from './components/hoc/authUsher';
import { Home } from './components/website/Home';
import { Error404 } from './components/website/Error404';
import { PrivacyAndTerms } from './components/website/PrivacyAndTerms';
import { About } from './components/website/About';

const RouterComponent = () => {
  return (
    <Switch>
      <Route path="/" exact component={AuthUsherHOC(Home)} />
      <Route path="/privacy-and-terms" exact component={PrivacyAndTerms} />
      <Route path="/about" exact component={About} />
      <Route path="/login" exact component={AuthUsherHOC(Login)} />
      <Route path="/notes/:id" component={AuthGuardHOC(Main)} />
      <Route component={Error404} />
    </Switch>
  );
};

export default RouterComponent;
