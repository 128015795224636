import { connect } from 'react-redux';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { IAppState } from '../../../store/reducers/index';
import { Right } from './Right';
import { IModuleAddress, IModules } from '../../../data-models';
import { firestoreConnect } from 'react-redux-firebase';
import {
  insertModule,
  deleteModule,
  updateModuleInStore,
  updateModuleOrder,
} from '../../../store/actions';

interface IStateToProps {}
interface IDispatchToProps {
  insertModule(moduleAddress: IModuleAddress, moduleType: string): void;
  deleteModule(
    noteId: string,
    sectionId: string,
    moduleIndex: number,
  ): void;
  updateModuleInStore(
    moduleAddress: IModuleAddress,
    updatedPayloadObj: any
  ): void;
  updateModuleOrder(
    noteId: string,
    sectionId: string,
    oldIndex: number,
    newIndex: number
  ):void;
}

interface IOwnProps {
  moduleAddress: IModuleAddress;
  rightModules: IModules;
  rightModulesOrder: string[];
}

export interface IRightProps
  extends IStateToProps,
    IDispatchToProps,
    IOwnProps {}

function mapStateToProps(
  state: IAppState,
  ownProps: IOwnProps
): IStateToProps & IOwnProps {
  const { moduleAddress, rightModules, rightModulesOrder } = ownProps;
  return {
    rightModules,
    rightModulesOrder,
    moduleAddress,
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>): any {
  return {
    insertModule: bindActionCreators(insertModule, dispatch),
    deleteModule: bindActionCreators(deleteModule, dispatch),
    updateModuleInStore: bindActionCreators(updateModuleInStore, dispatch),
    updateModuleOrder: bindActionCreators(updateModuleOrder, dispatch),
  };
}

export const RightContainer = compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: 'notes' }])
)(Right);
