import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IAppState } from '../../../store/reducers/index';
import { RichEditor } from './RichEditor';
import { updateModuleInStore } from '../../../store/actions';
import { IModuleAddress } from '../../../data-models';

interface IStateToProps {}
interface IDispatchToProps {
  updateModuleInStore(
    moduleAddress: IModuleAddress,
    updatedPayloadObj: {markdown: string}
  ): void;
}
interface IOwnProps {
  payloadObj: {markdown: string};
  sectionsLength: number;
  moduleAddress: IModuleAddress;
}

export interface IRichEditorProps
  extends IStateToProps,
    IDispatchToProps,
    IOwnProps {}

function mapStateToProps(state: IAppState, ownProps: IOwnProps): IStateToProps {
  const { payloadObj, sectionsLength, moduleAddress } = ownProps;
  return {
    payloadObj,
    sectionsLength,
    moduleAddress,
  };
}

//FUTURE: fix any type
function mapDispatchToProps(dispatch: Dispatch<any>): any {
  return {
    updateModuleInStore: bindActionCreators(updateModuleInStore, dispatch),
  };
}

//FUTURE: fix as any
export const RichEditorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RichEditor) as any;
