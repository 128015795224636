import { ISection } from "../data-models";

export const blankSection:ISection = {
  id: '',
  leftModulesOrder: ['module-1'],
  rightModulesOrder: ['module-1'],
  leftModules: {
    'module-1': {
      id: 'module-1',
      type: 'markdown',
      payloadObj: {
        markdown: `## New Section`,
      },
      moduleAddress: {
        noteId: '',
        sectionId: '',
        leftModulesId: '',
      },
    },
  },
  rightModules: {
    'module-1': {
      id: 'module-1',
      type: 'code',
      payloadObj: {
        code: `//new code block`,
        settings: {
          languageLabel: 'Javascript',
          wrapCode: true,
          theme: '',
          showLineNumbers: true,
          readOnly: false,
          tabSize: 2,
          fontSize: 14,
          enableLiveAutocompletion: false,
          linterOn: false,
        },
      },
      moduleAddress: {
        noteId: '',
        sectionId: '',
        rightModulesId: '',
      },
    },
  },
};
