enum NoteAction {
  createNote = 'CREATE_NOTE',
  createNoteError = 'CREATE_NOTE_ERROR',
  downloadNote = 'DOWNLOAD_NOTE',
  downloadNoteError = 'DOWNLOAD_NOTE_ERROR',

  storeNoteFromServer = 'STORE_NOTE_FROM_SERVER',
  storeNNotesFromServer = 'STORE_N_NOTES_FROM_SERVER',

  setNoteIdOnDisplay = 'SET_NOTE_ID_ON_DISPLAY',
  cacheNote = 'CACHE_NOTE',

  getNote = 'GET_NOTE',
  getNoteError = 'GET_NOTE_ERROR',
  loadNNotes = 'LOAD_N_NOTES',
  isCurrentNoteDownloaded = 'IS_CURRENT_NOTE_DOWNLOADED',

  isNewNoteCreated = 'IS_NEW_NOTE_CREATED',
  redirect = 'REDIRECT',
  noteExistsInDB = 'NOTE_EXISTS_IN_DB',
  hasChanges = 'HAS_CHANGES',

  updateSectionOrder = 'UPDATE_SECTION_ORDER',
  updateModuleOrder = 'UPDATE_MODULE_ORDER',

  updateModuleInStore = 'UPDATE_CONTENT_IN_STORE',
  updateEditorState = 'UPDATE_EDITOR_STATE',

  insertModule = 'INSERT_SECONDARY_MODULE',
  deleteModule = 'DELETE_SECONDARY_MODULE',

  updateTitleInStore = 'UPDATE_TITLE_IN_STORE',
  insertSection = 'INSERT_SECTION',
  deleteSection = 'DELETE_SECTION',

  moveNoteToTrash = 'MOVE_NOTE_TO_TRASH',
  restoreNoteFromTrash = 'RESTORE_NOTE_FROM_TRASH'
}

enum UserAction {
  loginSuccess = 'LOGIN_SUCCESS',
  loginError = 'LOGIN_ERROR',
  signOutSuccess = 'SIGNOUT_SUCCESS',
  signUpSuccess = 'SIGNUP_SUCCESS',
  signUpError = 'SIGNUP_ERROR',
}

enum UtilityAction {
  showUtlityModal = 'SHOW_UTILITY_MODAL',
  hideUtilityModal = 'HIDE_UTILITY_MODAL',
  toggleSidebarOrderLabel = 'TOGGLE_SIDEBAR_ORDER_LABEL',
  toggleSidebarAscOrDesc = 'TOGGLE_SIDEBAR_ASC_OR_DESC',
  updateSidebarCategory = 'UPDATE_SIDEBAR_CATEGORY'
}

export {
  NoteAction,
  UserAction,
  UtilityAction
};
