import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators, compose } from 'redux';
// @ts-ignore
import TextLoop from 'react-text-loop';
// @ts-ignore
import Parallax from 'react-rellax';
// @ts-ignore
import TextareaAutosize from 'react-textarea-autosize';

import { styled } from '../common/ThemedStyledComponent';
import { Layout } from './Layout';
import screenshot1 from './screenshot-1.png';
import hero_banner from './hero_banner.svg';
import ContentLoader from 'react-content-loader';
import { SectionsListContainer } from '../Note/SectionsList/SectionsListContainer';
import { createNoteTemplate } from '../../templates';
import { SectionsList } from '../Note/SectionsList/SectionsList';
import { ISections, INote } from '../../data-models';
import {
  updateSectionOrder,
  setUpNewUser,
  createNote,
} from '../../store/actions';
import { IAppState } from '../../store/reducers';
import { Link } from 'react-router-dom';
import LoginForm from '../auth/LoginForm';

interface IProps {
  homeNote: INote;
  connectedUpdateSectionOrder(
    noteId: string,
    oldIndex: number,
    newIndex: number
  ): void;
  setUpNewUser(newUser: any): void;
  createNote(newNote: INote): void;
}

const Home = (props: IProps) => {
  const [title, setTitle] = useState('All the neat features 🌟');
  const { connectedUpdateSectionOrder, homeNote } = props;
  const note = homeNote;
  return (
    <Layout>
      <Parallax>
        <HeroContainer>
          <HeroCenter>
            <h1 style={{ textAlign: 'center' }}>
              Simple, organized notes for{' '}
              <StyledTextLoop className="textLoopStyles">
                <CenteredTextLoopText>studying</CenteredTextLoopText>
                <CenteredTextLoopText>documenting</CenteredTextLoopText>
                <CenteredTextLoopText>researching</CenteredTextLoopText>
                <CenteredTextLoopText>learning</CenteredTextLoopText>
              </StyledTextLoop>
            </h1>
            <HeroCTA>
              <Link to="/login">
                <Button>Sign up</Button>
              </Link>
            </HeroCTA>
            {/* <img src={hero_banner} /> */}
          </HeroCenter>
        </HeroContainer>
      </Parallax>
      <Parallax speed={6}>
        <ContentContainer>
          {/* <NoteDemo>
            <NoteDemoHeader>
              <StyledTextAreaAutosize
                value={title}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setTitle(event.target.value);
                }}
                placeholder="Title"
              />
            </NoteDemoHeader>
            <SectionsList
              urlNoteId="homeDemo"
              sections={note.sections}
              sectionsOrder={note.sectionsOrder}
              updateSectionOrder={connectedUpdateSectionOrder}
            />
          </NoteDemo> */}
          {/* <FooterCTA>
            <LoginForm label="Try WingNotes for free" />
          </FooterCTA> */}
        </ContentContainer>
      </Parallax>
    </Layout>
  );
};

// Lazy to create a HomeContainer file.
function mapStateToProps(state: IAppState, ownProps: any) {
  return {
    homeNote: state.notesStore.downloadedUserNotes['homeDemo'],
  };
}

// FUTURE: Make SectionsList handle updateSectionsOrder on its own
function mapDispatchToProps(dispatch: Dispatch<any>): any {
  return {
    // Renamed to avoid naming conflict due to single file
    connectedUpdateSectionOrder: bindActionCreators(
      updateSectionOrder,
      dispatch
    ),
    setUpNewUser: bindActionCreators(setUpNewUser, dispatch),
    createNote: bindActionCreators(createNote, dispatch),
  };
}

const ConnectedHome = connect(
  mapStateToProps,
  mapDispatchToProps
)(Home as any);

export { ConnectedHome as Home };

const HeroContainer = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 40px auto 30px;
  display: flex;
  flex: 1 0 auto;
  height: 70vh;
  justify-content: center;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url('../hero_banner.svg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  @media (max-width: 520px) {
    background-size: 150% auto;
  }
`;
const HeroCenter = styled.div`
  margin: 100px 0px 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5;
  img {
    max-width: 100%;
    opacity: 0.6;
    margin-top: -250px;
    z-index: -2;
  }
  h1 {
    font-size: 2.5rem;
  }
  @media (max-width: 550px) {
    margin: 75px 0px 100px;
    h1 {
      font-size: 2rem;
    }
  }
`;

const HeroCTA = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 550px) {
    margin-top: 5px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: -380px;
`;

const NoteDemoHeader = styled.div`
  padding: 0px 4% 0px 5.5%;
  width: 100%;
  box-sizing: border-box;
`;

const StyledTextAreaAutosize = styled(TextareaAutosize)`
  border: 0px;
  background-color: transparent;
  padding: 4px 0px 4px 4px;
  font-family: 'Inter UI', Helvetica, Arial, sans-serif;
  font-size: 2em;
  font-weight: 600;
  resize: none;
  overflow-wrap: break-word;
  width: 100%;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #f8f8f8;
  }
`;

const NoteDemo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 7px;
  z-index: 10;
  background: white;
  box-shadow: 0px 5px 50px -5px #e4e4e4;
  padding: 70px 0px 30px;
  margin: 0px auto 150px;
`;

const StyledTextLoop = styled(TextLoop)`
  display: flex;
  align-items: center;
  width: 275px;
  padding-bottom: 15px;
  border-bottom: 4px solid black;
  @media (max-width: 550px) {
    padding: 3px;
    border-bottom: 3px solid black;
  }
`;

const CenteredTextLoopText = styled.div`
  display: flex;
  width: 275px;
  justify-content: center;
`;

const Input = styled.input`
  display: flex;
  border: 0px;
  flex: 1.5 1;
  box-shadow: none;
  background-color: transparent;
  font-size: 2em;
  font-weight: 600;
  max-height: fit-content;
  /* to compensate for note's left-side buttons */
  padding: 10px 0px 10px 2px;
  flex: 1.5 1;
  width: 100%;
  /* to establish border width so that hover does not move Input */
  border: 1px solid transparent;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #f8f8f8;
  }
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 4px;
  color: white;
  /* border: 1px solid #adadad; */
  background-color: #4e42ff;
  box-shadow: 0 1px 1px 0 rgba(10, 16, 34, 0.2);
  transition: all 0.1s ease;
  &:hover {
    transform: scale(1.05);
  }
`;

const FooterCTA = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto 40px;
`;
