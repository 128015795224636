import { NoteAction, UserAction, UtilityAction } from '../constants';
import { Dispatch } from 'redux';
import { INote, IModuleAddress, ISection } from '../../data-models';
import { IAppState, INotesObj } from '../reducers';
import { createNoteTemplate } from '../../templates';
import { NetworkProvider } from '../NetworkProvider';
import { Mixpanel } from '../../helpers/Mixpanel';

// ------------ NOTES ------------

//synchronous action creators, used in async functions below

const isCurrentNoteDownloaded = (isCurrentNoteDownloaded: boolean) => {
  return { type: NoteAction.isCurrentNoteDownloaded, isCurrentNoteDownloaded };
};

//to trigger redirect on new sign in, new note created, invalid note url, and moveToTrash
const redirect = (redirect: boolean) => {
  return { type: NoteAction.redirect, redirect };
};

//to trigger save button
const hasChanges = (hasChanges: boolean) => {
  return {
    type: NoteAction.hasChanges,
    hasChanges,
  };
};

const storeNoteFromServer = (urlParamsId: string, downloadedNote: INote) => {
  return {
    type: NoteAction.storeNoteFromServer,
    urlParamsId,
    downloadedNote,
  };
};

const storeNNotesFromServer = (nNotes: INotesObj) => {
  return {
    type: NoteAction.storeNNotesFromServer,
    nNotes,
  };
};

const setNoteIdOnDisplay = (urlParamsId: string) => {
  return {
    type: NoteAction.setNoteIdOnDisplay,
    urlParamsId,
  };
};

const noteExistsinDB = (noteExistsInDB: boolean) => {
  return {
    type: NoteAction.noteExistsInDB,
    noteExistsInDB,
  };
};

//async functions
export const createNote = (newNote: INote) => {
  return async (dispatch: Dispatch<any>, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    try {
      const newNoteId = await NetworkProvider.createNote(newNote);
      if (email) {
        Mixpanel.identify(email);
        Mixpanel.track('Created Note');
      }
      dispatch({
        type: NoteAction.createNote,
        newNoteId,
        newNote,
      });
      dispatch(redirect(true));
      dispatch(redirect(false));
    } catch (err) {
      dispatch({ type: NoteAction.createNoteError, err });
    }
  };
};

//display the note from local storage
//download first if doesn't exist
export const displayNote = (urlParamsId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const userEmail: string = state.firebase.auth.email;
    try {
      const downloadedNote = await NetworkProvider.downloadNote(
        urlParamsId,
        userEmail
      );
      dispatch(noteExistsinDB(true));
      dispatch(storeNoteFromServer(urlParamsId, downloadedNote));
      dispatch(setNoteIdOnDisplay(urlParamsId));
      dispatch(hasChanges(false));
    } catch (error) {
      //if error, set noteExistsInDB to false?
      dispatch(redirect(true));
      dispatch(redirect(false));
      return;
    }
  };
};

// download N Notes
export const downloadNNotes = (num: number) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch(isCurrentNoteDownloaded(false));
    const state: IAppState = getState();
    const userEmail = state.firebase.auth.email;
    const nNotes = await NetworkProvider.downloadNNotes(num, userEmail);
    dispatch(storeNNotesFromServer(nNotes as INotesObj));
    dispatch(isCurrentNoteDownloaded(true));
  };
};

export const saveCurrentNote = (noteId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const currentNote = state.notesStore.downloadedUserNotes[noteId];
    try {
      await NetworkProvider.uploadNote(noteId, currentNote);
      const email = state.firebase.auth.email;
      if (email) {
        Mixpanel.identify(email);
        Mixpanel.track('Saved Current Note');
      }
      dispatch(hasChanges(false));
    } catch (err) {
      throw err;
    }
  };
};

export const updateSectionOrder = (
  noteId: string,
  oldIndex: number,
  newIndex: number
) => {
  return (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    if (email) {
      Mixpanel.identify(email);
      Mixpanel.track('Updated Section Order');
    }
    dispatch(hasChanges(true));
    dispatch({
      type: NoteAction.updateSectionOrder,
      noteId,
      oldIndex,
      newIndex,
    });
  };
};

export const updateModuleOrder = (
  noteId: string,
  sectionId: string,
  oldIndex: number,
  newIndex: number
) => {
  return (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    if (email) {
      Mixpanel.identify(email);
      Mixpanel.track('Updated Module Order');
    }
    dispatch(hasChanges(true));
    dispatch({
      type: NoteAction.updateModuleOrder,
      noteId,
      sectionId,
      oldIndex,
      newIndex,
    });
  };
};

// update component content to local storage only
export const updateModuleInStore = (
  moduleAddress: IModuleAddress,
  updatedPayloadObj: any
) => {
  return (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    if (email) {
      Mixpanel.identify(email);
      Mixpanel.track('Updated Module in Store');
    }
    dispatch(hasChanges(true));
    dispatch({
      type: NoteAction.updateModuleInStore,
      moduleAddress,
      updatedPayloadObj,
    });
  };
};

//insert module
export const insertModule = (
  moduleAddress: IModuleAddress,
  moduleType: string,
  moduleIndex: number
) => {
  return (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    if (email) {
      Mixpanel.identify(email);
      Mixpanel.track(`Inserted Module`, { moduleType });
      Mixpanel.people.increment('modules_created');
    }
    dispatch(hasChanges(true));
    dispatch({
      type: NoteAction.insertModule,
      moduleAddress,
      moduleType,
      moduleIndex,
    });
  };
};

export const deleteModule = (
  noteId: string,
  sectionId: string,
  moduleIndex: number
) => {
  return (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    if (email) {
      Mixpanel.identify(email);
      Mixpanel.people.increment('modules_created', -1);
    }
    dispatch(hasChanges(true));
    dispatch({
      type: NoteAction.deleteModule,
      noteId,
      sectionId,
      moduleIndex,
    });
  };
};

// update title in store
export const updateTitleInStore = (updatedTitle: string) => {
  return (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const noteId = state.notesStore.currentNoteStatus.noteId;
    dispatch(hasChanges(true));
    dispatch({
      type: NoteAction.updateTitleInStore,
      updatedTitle,
      noteId,
    });
  };
};

// insert section in store
export const insertSection = (
  noteId?: string,
  sectionIndex?: number,
  sectionToInsert?: ISection
) => {
  return (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    if (email) {
      Mixpanel.identify(email);
      Mixpanel.track('Inserted Section');
      Mixpanel.people.increment('sections_created');
    }
    dispatch(hasChanges(true));
    dispatch({
      type: NoteAction.insertSection,
      noteId,
      sectionIndex,
      sectionToInsert,
    });
  };
};

// delete section in store
export const deleteSection = (noteId: string, sectionIndex: number) => {
  return (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    if (email) {
      Mixpanel.identify(email);
      Mixpanel.track('Deleted Section');
      Mixpanel.people.increment('sections_created', -1);
    }
    dispatch(hasChanges(true));
    dispatch({
      type: NoteAction.deleteSection,
      noteId,
      sectionIndex,
    });
  };
};

export const moveNoteToTrash = (noteId: string) => {
  return (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    if (email) {
      Mixpanel.identify(email);
      Mixpanel.track('Moved Note to Trash');
    }
    dispatch({
      type: NoteAction.moveNoteToTrash,
      noteId,
    });
    //@ts-ignore: using dispatch to call saveCurrentNote allows note state to finish updating before calling saveCurrentNote
    dispatch(saveCurrentNote(noteId));
  };
};

export const restoreNoteFromTrash = (noteId: string) => {
  return (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    if (email) {
      Mixpanel.identify(email);
      Mixpanel.track('Restored Note From Trash');
    }
    dispatch({
      type: NoteAction.restoreNoteFromTrash,
      noteId,
    });
    //@ts-ignore
    dispatch(saveCurrentNote(noteId));
  };
};

export const permDeleteNote = (noteId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    if (email) {
      Mixpanel.identify(email);
      Mixpanel.track('Permanently Deleted Note');
    }
    await NetworkProvider.permDeleteNote(noteId);
    //FUTURE: fix redirect on permDeleteNote, currently does not redirect user to newest note
    // dispatch(redirect(true));
    // dispatch(redirect(false));
  };
};

// ------------ USER ------------

export const signOut = () => {
  return async (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    if (email) {
      Mixpanel.identify(email);
      Mixpanel.track('Signed Out');
    }
    await NetworkProvider.signOut();
    window.localStorage.clear();
    dispatch({
      type: UserAction.signOutSuccess,
    });
  };
};

export const setUpNewUser = (newUser: any) => {
  return (dispatch: Function) => {
    const isDevelopment = String(process.env.NODE_ENV) === 'development';
    dispatch({ type: UserAction.signUpSuccess });
    dispatch(
      createNote(createNoteTemplate(newUser.email, 'algorithmsExample'))
    );
    dispatch(createNote(createNoteTemplate(newUser.email, 'example2')));
    // dispatch(createNote(createNoteTemplate(newUser.email, 'meetingExample')));
    dispatch(createNote(createNoteTemplate(newUser.email, 'welcome')));
    dispatch({ type: UserAction.signUpSuccess });
    // dispatch({ type: UserAction.signUpError});
  };
};

export const updateSidebarCategory = (category: string) => {
  return (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    if (email) {
      Mixpanel.identify(email);
      Mixpanel.track('Updated Sidebar Category');
    }
    dispatch({
      type: UtilityAction.updateSidebarCategory,
      category,
    });
  };
};

export const toggleSidebarOrderLabel = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UtilityAction.toggleSidebarOrderLabel,
    });
  };
};

export const toggleSidebarAscOrDesc = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UtilityAction.toggleSidebarAscOrDesc,
    });
  };
};
