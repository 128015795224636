import React, { Component } from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
import * as Icon from 'react-feather';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { IModuleAddress } from '../../data-models';
import { styled } from './ThemedStyledComponent';

interface IState {
  wrapCode: boolean;
}
interface IProps {
  handleDeleteModuleClick(moduleIndex: number): void;
  index: number;
  children: any;
  handleOptionsClick(
    moduleId: string,
    optionLabel: string,
    optionValue: any
  ): void;
  payloadObj: any;
  moduleAddress: IModuleAddress;
}

class ModuleWrapper extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      wrapCode: this.props.payloadObj.wrapCode || true,
    };
  }

  public render() {
    const { index, children, handleDeleteModuleClick, payloadObj } = this.props;
    return (
      <StyledModule>
        {/* ----Option Buttons---- */}
        <StyledModuleButtonGroup size="sm">
          <Dropdown style={{ position: 'unset' }}>
            <Dropdown.Toggle
              //@ts-ignore
              variant="transparent"
              id="dropdown-basic"
              style={{
                padding: '2.3px',
                borderRadius: '3px 0px 0px 3px',
              }}
            >
              <Icon.MoreHorizontal size={14} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Header>Header</Dropdown.Header> */}
              {payloadObj.code ? (
                <ToggleButton
                  variant="transparent"
                  onClick={this.handleWrapToggle}
                  checked={this.state.wrapCode}
                  value={'wrapCode'}
                  type="checkbox"
                >
                  Wrap
                </ToggleButton>
              ) : (
                <ToggleButton
                  variant="transparent"
                  onClick={()=>{}}
                  disabled={true}
                  value={'wrapCode'}
                  type="checkbox"
                >
                  N/A
                </ToggleButton>
              )}
            </Dropdown.Menu>
          </Dropdown>
          <Button
            variant="secondary"
            onClick={() => handleDeleteModuleClick(index)}
            style={{ padding: '3.3px', position: 'unset' }}
          >
            <Icon.X size={12} />
          </Button>
        </StyledModuleButtonGroup>

        {/* ----children---- */}
        {children}
      </StyledModule>
    );
  }
  private handleWrapToggle = () => {
    const { index, payloadObj, handleOptionsClick, moduleAddress } = this.props;
    const { rightModulesId } = moduleAddress;
    this.setState({
      wrapCode: !this.state.wrapCode,
    });
    handleOptionsClick(
      rightModulesId as string,
      'wrapCode',
      !payloadObj.settings.wrapCode
    );
  };
}

export { ModuleWrapper };

const StyledModuleButtonGroup = styled(ButtonGroup)`
  display: flex;
  align-items: center;
  width: fit-content;
  align-self: flex-end;
  opacity: 1;
  transition: all 0.4s;
  position: absolute;
  top: 20px;
  top: 0px;
  right: 12px;
  z-index: 2;
  /* top 'padding' of StyledModuleButtonGroup since Icon.MoreHorizontal would otherwise force the 'height' to be the icon's size */
  height: 0px;
  .btn-secondary {
    display: flex;
    align-items: center;
    padding: 2px;
  }
  .btn {
    display: flex;
    background-color: #f8f8f8;
    border: 1px solid rgba(0, 0, 0, 0.05);
    align-items: center;
    padding: 2px;
  }
  .btn-group-sm > .btn {
    padding: 2px;
  }
`;

const StyledModule = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgba(237, 230, 230, 1);
  border-radius: 6px;
  box-shadow: -2px 4px 12px 1px rgba(0, 0, 0, 0.05);
  border: 1px solid transparent;
  /* border: 1px solid rgba(0, 0, 0, 0.05); */
  transition: all 0.4s;
  position: relative;
  &:hover ${StyledModuleButtonGroup} {
    opacity: 1;
    transition: all 0.4s;
  }
  &:focus-within {
    border: 1px solid rgba(256, 256, 256, 0.9);
    box-shadow: -2px 4px 12px 1px rgba(0, 0, 0, 0.05);
  }
`;
