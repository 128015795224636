import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IAppState } from '../../../store/reducers/index';
import { LinkInput } from '.';
import { updateModuleInStore } from '../../../store/actions';
import { IModuleAddress } from '../../../data-models';

interface IStateToProps {}
interface IDispatchToProps {
  updateModuleInStore(
    moduleAddress: IModuleAddress,
    updatedPayloadObj: any
  ): void;
}
interface IOwnProps {
  value: string | undefined;
  moduleAddress: IModuleAddress,
  payloadObj: {
    link: string,
    linkType: string
  }
}

export interface ILinkInputProps
  extends IStateToProps,
    IDispatchToProps,
    IOwnProps {}

function mapStateToProps(
  state: IAppState,
  ownProps: IOwnProps
): IStateToProps & IOwnProps {
  const {value, moduleAddress, payloadObj} = ownProps;
  return {
    value,
    moduleAddress,
    payloadObj
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>): any {
  return {
    updateModuleInStore: bindActionCreators(updateModuleInStore, dispatch),
  };
}

export const LinkInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkInput) as any;
