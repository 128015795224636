import { IModule } from "../data-models";

export const newListBoard: IModule = {
  id: Math.random().toString(36).substr(2, 9),
  type: 'List Board',
  payloadObj: {
    listBoardData: {
      lanes: [
        {
          id: 'lane1',
          title: 'List Board',
          label: '',
          cards: [
            // {
            //   id: 'Card1',
            //   title: 'Write Blog',
            //   description: 'Can AI make memes',
            //   label: '30 mins',
            // },
            // {
            //   id: 'Card2',
            //   title: 'Pay Rent',
            //   description: 'Transfer via NEFT',
            //   label: '5 mins',
            //   metadata: { sha: 'be312a1' },
            // },
          ],
        },
      ],
    },
  },
  moduleAddress: {
    noteId: '',
    sectionId: '',
    rightModulesId: '',
  },
};
