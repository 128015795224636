import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IAppState } from '../../store/reducers/index';
import { Sidebar } from './Sidebar';
import { createNote, signOut, updateSidebarCategory, toggleSidebarOrderLabel, toggleSidebarAscOrDesc } from '../../store/actions';
import { INote } from '../../data-models';
import { History } from 'history';

interface IStateToProps {
  userEmail: string;
  notes: INote[];
  category: string;
  sidebarOrderLabel: string;
  sidebarAscOrDesc: string;
}
interface IDispatchToProps {
  createNote(newNote: INote): void;
  signOut(): void;
  updateSidebarCategory(category: string): void;
  toggleSidebarOrderLabel(): void;
  toggleSidebarAscOrDesc(): void;
}
interface IOwnProps {
  // history: History;
}

export interface ISidebarProps
  extends IStateToProps,
    IDispatchToProps,
    IOwnProps {}

function mapStateToProps(
  state: IAppState,
  ownProps: IOwnProps
): IStateToProps & IOwnProps {
  return {
    notes: Object.keys(state.notesStore.downloadedUserNotes).map(noteId => {
      return state.notesStore.downloadedUserNotes[noteId];
    }),
    userEmail: state.firebase.auth.email,
    category: state.utility.sidebarCategory,
    sidebarOrderLabel: state.utility.sidebarOrderLabel as string,
    sidebarAscOrDesc: state.utility.sidebarAscOrDesc as string,
  };
}

//FUTURE: fix 'any' typing
function mapDispatchToProps(dispatch: Dispatch<any>): any {
  return {
    createNote: bindActionCreators(createNote, dispatch),
    signOut: bindActionCreators(signOut, dispatch),
    updateSidebarCategory: bindActionCreators(updateSidebarCategory, dispatch),
    toggleSidebarOrderLabel: bindActionCreators(toggleSidebarOrderLabel, dispatch),
    toggleSidebarAscOrDesc: bindActionCreators(toggleSidebarAscOrDesc, dispatch),
  };
}

export const SidebarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar) as any;
