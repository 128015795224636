import { NoteAction } from '../constants';
import { defaultNotesReducer, createBlankCodeEditor } from '../../templates';
import producer from 'immer';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { createNewLink } from '../../templates/newLink';
import { newListBoard } from '../../templates/newListBoard';
import { arrayMove } from 'react-sortable-hoc';

const firestore = firebase.firestore;

//of type INotesLocalStore
export const notesReducer = (state = defaultNotesReducer, action: any) => {
  // producer creates a copy of the state (draft)
  // draft is able to be mutated
  // and immutable object based on draft is then returned

  return producer(state, draft => {
    switch (action.type) {
      case NoteAction.storeNoteFromServer:
        const urlParamsId = action.urlParamsId;
        draft.downloadedUserNotes[urlParamsId] = action.downloadedNote;
        return;

      case NoteAction.storeNNotesFromServer:
        Object.assign(draft.downloadedUserNotes, action.nNotes);
        return;

      case NoteAction.createNote:
        //add newNote to downloadedUserNotes object (in local storage)
        const newNote = Object.assign({}, action.newNote);
        newNote['noteId'] = action.newNoteId;
        newNote['default'] = false;
        draft.downloadedUserNotes[action.newNoteId] = newNote;
        draft.downloadedUserNotes[action.newNoteId].noteId = action.newNoteId;
        draft.downloadedUserNotes[action.newNoteId].default = false;
        return;

      case NoteAction.createNoteError:
        return;

      case NoteAction.redirect:
        draft.redirect = action.redirect;
        return;

      case NoteAction.getNoteError:
        //FUTURE: add array of error messages
        return;

      case NoteAction.isCurrentNoteDownloaded:
        draft.currentNoteStatus.isDownloaded = action.isCurrentNoteDownloaded;
        return;

      case NoteAction.noteExistsInDB:
        draft.currentNoteStatus.noteExistsInDB = action.noteExistsInDB;
        return;

      //changes to currentNote
      case NoteAction.insertSection:
        //FUTURE: figure out way to define noteId multiple places in immer
        //without mutating
        var { noteId, sectionToInsert } = action;
        const indexToInsert = action.sectionIndex + 1;
        const newSectionId = sectionToInsert.id;
        draft.downloadedUserNotes[noteId].sectionsOrder.splice(
          indexToInsert,
          0,
          newSectionId
        );
        draft.downloadedUserNotes[noteId].sections[
          newSectionId
        ] = sectionToInsert;
        return;

      case NoteAction.deleteSection:
        var noteId = action.noteId;
        const indexToDelete = action.sectionIndex;
        draft.downloadedUserNotes[noteId].sectionsOrder.splice(
          indexToDelete,
          1
        );
        delete draft.downloadedUserNotes[noteId].sections[newSectionId];
        return;

      case NoteAction.updateSectionOrder:
        const sectionsOrder =
          draft.downloadedUserNotes[action.noteId].sectionsOrder;
        draft.downloadedUserNotes[action.noteId].sectionsOrder = arrayMove(
          sectionsOrder,
          action.oldIndex,
          action.newIndex
        );
        return;

      case NoteAction.updateModuleOrder:
        const rightModuleOrder =
          draft.downloadedUserNotes[action.noteId].sections[action.sectionId].rightModulesOrder;
        draft.downloadedUserNotes[action.noteId].sections[action.sectionId].rightModulesOrder = arrayMove(
          rightModuleOrder,
          action.oldIndex,
          action.newIndex
        );
        return;

      case NoteAction.insertModule:
        //FUTURE: update noteId, sectionIndex, and rightIndex for new module
        var { noteId, sectionId } = action.moduleAddress;
        var { moduleType, moduleIndex } = action;
        var modulesOrderIndexToInsert = moduleIndex + 1;
        if (action.moduleType === 'code') {
          const newCodeEditor = createBlankCodeEditor();
          const newCodeEditorId = newCodeEditor.id;
          draft.downloadedUserNotes[noteId].sections[
            sectionId
          ].rightModulesOrder.splice(
            modulesOrderIndexToInsert,
            0,
            newCodeEditorId
          );
          draft.downloadedUserNotes[noteId].sections[sectionId].rightModules[
            newCodeEditorId
          ] = newCodeEditor;
        } else if (
          moduleType === 'Preview' ||
          moduleType === 'Image' ||
          moduleType === 'Video' ||
          moduleType === 'Audio'
        ) {
          const newLink = createNewLink(moduleType);
          const newLinkId = newLink.id;
          draft.downloadedUserNotes[noteId].sections[
            sectionId
          ].rightModulesOrder.splice(modulesOrderIndexToInsert, 0, newLinkId);
          draft.downloadedUserNotes[noteId].sections[sectionId].rightModules[
            newLinkId
          ] = newLink;
        } else if (moduleType === 'List Board') {
          const newListBoardId = newListBoard.id;
          draft.downloadedUserNotes[noteId].sections[
            sectionId
          ].rightModulesOrder.splice(
            modulesOrderIndexToInsert,
            0,
            newListBoardId
          );
          draft.downloadedUserNotes[noteId].sections[sectionId].rightModules[
            newListBoardId
          ] = newListBoard;
        }
        return;

      case NoteAction.deleteModule:
        //FUTURE: update noteId, sectionIndex, and moduleIndex for new module
        var { noteId, sectionId, moduleIndex: moduleIndexToDelete } = action;
        // grab moduleId to delete from rightModulesOrder first
        var moduleId =
          draft.downloadedUserNotes[noteId].sections[sectionId]
            .rightModulesOrder[moduleIndexToDelete];

        // delete moduleId from rightModulesOrder array
        draft.downloadedUserNotes[noteId].sections[
          sectionId
        ].rightModulesOrder.splice(moduleIndexToDelete, 1);

        // then delete module object from rightModules
        delete draft.downloadedUserNotes[noteId].sections[sectionId]
          .rightModules[moduleId];
        return;

      case NoteAction.setNoteIdOnDisplay:
        draft.currentNoteStatus.noteId = action.urlParamsId;
        return;

      case NoteAction.updateModuleInStore:
        //FUTURE: fix updating cached downloadedNotes
        //sectionIndex, left/rightIndex, defined above
        var {
          noteId,
          sectionId,
          leftModulesId,
          rightModulesId,
        } = action.moduleAddress;

        if (leftModulesId) {
          //update currentNoteOnDisplay
          draft.downloadedUserNotes[noteId].sections[sectionId].leftModules[
            leftModulesId
          ].payloadObj = action.updatedPayloadObj;
        }
        if (rightModulesId) {
          //update currentNoteOnDisplay
          draft.downloadedUserNotes[noteId].sections[sectionId].rightModules[
            rightModulesId
          ].payloadObj = action.updatedPayloadObj;
        }
        draft.downloadedUserNotes[noteId].updatedAt = firestore.Timestamp.now();
        return;
      case NoteAction.updateTitleInStore:
        var noteId = action.noteId;
        draft.downloadedUserNotes[noteId].title = action.updatedTitle;
        return;
      case NoteAction.moveNoteToTrash:
        var noteId = action.noteId;
        draft.downloadedUserNotes[noteId].inTrash = true;
        return;
      case NoteAction.restoreNoteFromTrash:
        var noteId = action.noteId;
        draft.downloadedUserNotes[noteId].inTrash = false;
        return;
      case NoteAction.hasChanges:
        draft.currentNoteStatus.hasChanges = action.hasChanges;
        return;
      //default case not needed bc immer returns original state if draft is not edited
    }
  });
};
