import { INote } from '../data-models';
import { firebase, firebaseDB } from '../config/fbConfig';
import { INotesObj } from './reducers';

export class NetworkProvider {
  public static async createNote(newNote: INote) {
    try {
      const docRef = await firebaseDB.collection('notes').add({
        ...newNote,
      });
      return docRef.id;
    } catch (err) {
      throw err;
    }
  }

  public static async downloadNote(urlParamsId: string, userEmail: string) {
    try {
      const snapshot = await firebaseDB
        .collection('notes')
        //check if user is allowed to access note
        .where('ownerIds', 'array-contains', userEmail)
        //grab note
        .where('noteId', '==', urlParamsId)
        .get();
      const downloadedNote = snapshot.docs[0].data() as INote; // docs is array of results; only want 1 doc
      return downloadedNote;
    } catch (err) {
      throw err;
    }
  }

  public static async downloadNNotes(num: number, userEmail: string) {
    try {
      //num not used: currently downloads all qualified notes
      const snapshot = await firebaseDB
        .collection('notes')
        //check if user is allowed to access note
        .where('ownerIds', 'array-contains', userEmail)
        //grab note
        .get();
      const doc = snapshot.docs[0]; // docs is array of results;
      if (doc && doc.exists) {
        const nNotes: INotesObj = {};
        snapshot.docs.forEach((doc: any) => {
          const noteId: string = doc.id;
          const note = doc.data();
          nNotes[noteId] = note;
        });
        return nNotes;
      } else {

        return {
          type: 'error',
        };
      }
    } catch (err) {
      throw err;
    }
  }

  public static async uploadNote(noteId: string, currentNote: INote) {
    try {
      const response = await firebaseDB
        .collection('notes')
        .doc(noteId)
        .update(currentNote);
      return response;
    } catch (err) {
      throw err;
    }
  }

  public static async permDeleteNote(noteId: string) {
    try {
      await firebaseDB
        .collection('notes')
        .doc(noteId)
        .delete();
    } catch (err) {
      throw err;
    }
  }

  public static async signIn(credentials: { email: string; password: string }) {
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(credentials.email, credentials.password);
    } catch (err) {
      throw err;
    }
  }

  public static async signOut() {
    try {
      await firebase.auth().signOut();
    } catch (err) {
      throw err;
    }
  }
}
