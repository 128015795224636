import React, { Component, Fragment } from 'react';
//@ts-ignore
import { Draggable } from 'react-beautiful-dnd';

import { LeftContainer } from './LeftContainer';
import { RightContainer } from './RightContainer';
import { styled } from '../../common/ThemedStyledComponent';
import { ISectionProps as IProps } from './SectionContainer';
import { blankSection } from '../../../templates/newBlankSection';
import { InsertSectionButton } from '../../common/InsertSectionButton';
import { SectionSettingsButton } from '../../common/SectionSettingsButton';
import dragHandleIcon from './dragHandleIcon.svg';

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import { createBlankSection } from '../../../templates';

interface IState {}

const DragHandle = SortableHandle(() => (
  <StyledDragHandle>
    <img src={dragHandleIcon} width="24" height="24" />
  </StyledDragHandle>
));

const SortableItem = SortableElement(
  ({
    children,
    onSelect,
    sectionsLength,
  }: {
    children: any;
    onSelect: any;
    sectionsLength: number;
  }) => (
    <FullWidthContainer>
      <StyledSideButtons>
        <SectionSettingsButton
          onSelect={onSelect}
          sectionsLength={sectionsLength}
        />
        <StyledDragHandleIcon>
          <DragHandle />
        </StyledDragHandleIcon>
      </StyledSideButtons>
      {children}
    </FullWidthContainer>
  )
);

class Section extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }
  public render() {
    const { moduleAddress, section, sectionsLength, index } = this.props;
    return (
      <Fragment>
        {/* First Insert Section button of a note */}
        {index === 0 ? (
          <InsertSectionButton
            onClick={this.handleInsertSectionClick}
            moduleAddress={moduleAddress}
            first={true}
          />
        ) : null}

        {/* Draggable Section that contains Section Settings Button, Left, and Right Modules */}
        <SortableItem
          key={`item-${index}`}
          index={index}
          onSelect={this.handleSectionSettingsSelect}
          sectionsLength={sectionsLength}
        >
          {/* <StyledSideButtons>
              <SectionSettingsButton
                onSelect={this.handleSectionSettingsSelect}
                sectionsLength={sectionsLength}
              />
              <StyledDragHandleIcon>
                <img
                  src={dragHandleIcon}
                  alt="dragHandleIcon"
                  width="20"
                  height="20"
                />
              </StyledDragHandleIcon>
            </StyledSideButtons> */}
          <Max1200pxContainer>
            <SectionMiddleWrapper>
              <LeftAndRightContainer>
                <LeftContainer
                  moduleAddress={moduleAddress}
                  leftModulesOrder={section.leftModulesOrder}
                  leftModules={section.leftModules}
                  sectionsLength={sectionsLength}
                />
                <RightContainer
                  moduleAddress={moduleAddress}
                  rightModulesOrder={section.rightModulesOrder}
                  rightModules={section.rightModules}
                />
              </LeftAndRightContainer>
            </SectionMiddleWrapper>
          </Max1200pxContainer>
        </SortableItem>

        {/* Insert Section Button after each section */}
        <InsertSectionButton
          onClick={this.handleInsertSectionClick}
          moduleAddress={moduleAddress}
        />
      </Fragment>
    );
  }

  private handleInsertSectionClick = (e: any, first?: boolean) => {
    const { insertSection, moduleAddress, index: sectionIndex } = this.props;
    if (first) {
      insertSection(moduleAddress.noteId, -1, createBlankSection());
    } else {
      insertSection(moduleAddress.noteId, sectionIndex, createBlankSection());
    }
  };

  private handleSectionSettingsSelect = (eventKey: string) => {
    const {
      insertSection,
      deleteSection,
      moduleAddress,
      section,
      index: sectionIndex,
    } = this.props;
    if (eventKey === 'delete') {
      deleteSection(moduleAddress.noteId, sectionIndex);
    } else if (eventKey === 'duplicate') {
      insertSection(moduleAddress.noteId, sectionIndex, section);
    }
  };
}

export { Section };

//FUTURE: refactor to organize styled components

export const StyledSideButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  opacity: 0;
  transition: all 0.4s;
  position: relative;
  top: 7px;
  right: 1.1em;
  width: 0px;
  /* margin-left: -30px;
  padding-right: 15px; */
`;

// div styled like transprent button bc Button is unable to be dragged
const StyledDragHandleIcon = styled.div`
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  border-color: transparent;
  background-color: transparent;
  width: max-content;
  height: fit-content;
  border-radius: 2px;
  box-shadow: none;
  &:hover {
    background-color: rgba(233, 230, 237, 1);
    border-color: transparent;
  }
  &:active {
    color: inherit;
    background-color: #e9e6e3;
  }
  &:focus {
    outline: none;
    background-color: rgba(233, 230, 237, 1);
    box-shadow: none;
  }
`;

const SectionMiddleWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 6px 0px;
`;

const LeftAndRightContainer = styled.div`
  width: 100%;
  display: flex;
  height: fit-content;
  /* media queries */
  @media (max-width: 769px) {
    flex-direction: column;
    align-items: center;
  }
`;

interface IFullWidthContainerProps {
  isDragging?: boolean;
}

const FullWidthContainer = styled.div<IFullWidthContainerProps>`
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  max-height: 100%;
  padding: 0px 4% 10px 5.5%;
  box-sizing: border-box;
  justify-content: center;
  background: ${props => (props.isDragging ? '#F4F4F4' : 'white')};
  box-shadow: ${props =>
    props.isDragging ? '-2px 4px 12px 1px rgba(0, 0, 0, 0.125)' : 'none'};
  border-radius: 4px;
  &:hover {
    background: #fbfbfb;
  }
  /* //FUTURE: fix so that hovering over FullWidthContainer of insertSectionButton reveals button */
  &:hover ${StyledSideButtons} {
    opacity: 1;
    transition: all 0.4s;
  }
  .sortableHelper {
    background: #f4f4f4;
    box-shadow: -2px 4px 12px 1px rgba(0, 0, 0, 0.125);
  }
  @media (max-width: 769px) {
    padding-left: calc(5.5% + 10px);
  }
`;

const Max1200pxContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  max-height: fit-content;
`;

const StyledDragHandle = styled.div`
  cursor: grab;
  z-index: 2;
`;
