import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
// import * as firebaseAdmin from 'firebase-admin';

const config = {
  apiKey: 'AIzaSyDGdtBEs5v-gBczggme_Ar7DsUENHmVLXU',
  authDomain: 'modular-d19c8.firebaseapp.com',
  databaseURL: 'https://modular-d19c8.firebaseio.com',
  projectId: 'modular-d19c8',
  storageBucket: '',
  messagingSenderId: '265203560580',
};

//firebase app setup
firebase.initializeApp(config);
const firebaseDB = firebase.firestore();
firebaseDB.settings({ timestampsInSnapshots: true });

//firebase admin setup
// var serviceAccount = require("../private/modular-d19c8-firebase-adminsdk-6x7js-775e82ae8a.json");

// firebaseAdmin.initializeApp({
//   credential: firebaseAdmin.credential.cert(serviceAccount),
//   databaseURL: "https://modular-d19c8.firebaseio.com"
// });

// const firebaseNotesRef = firebaseDB.ref('notes');
// const firebaseUsersRef = firebaseDB.ref('users');

export {
  firebase,
  firebaseDB,
  // firebaseAdmin
  // firebaseNotesRef,
  // firebaseUsersRef
};
