import React, { Component, Fragment } from 'react';
import { styled } from '../common/ThemedStyledComponent';
import { NavLink } from 'react-router-dom';
import { INote } from '../../data-models';
import { createNoteTemplate } from '../../templates';
import { ISidebarProps as IProps } from './SidebarContainer';
import * as Icon from 'react-feather';
import newNoteIcon from './newNoteIcon.svg';
import moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';
import orderBy from 'lodash/orderBy';
import isEqual from 'lodash/isEqual';
import producer from 'immer';
import { CenteredModal } from '../common/CenteredModal';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ContentLoader from 'react-content-loader';
import { FeedbackModal } from '../common/FeedbackModal';

interface IState {
  showSettingsModal: boolean;
  showFeedbackModal: boolean;
  orderLabel: string;
  ascOrDesc: string;
  orderedNotes: INote[];
  category: string;
  sidebarShow: boolean;
}

class Sidebar extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { sidebarOrderLabel, sidebarAscOrDesc } = this.props;
    this.state = {
      showSettingsModal: false,
      showFeedbackModal: false,
      orderedNotes: orderBy(this.props.notes, sidebarOrderLabel, (sidebarAscOrDesc as any)),
      category: 'All',
      sidebarShow: true,
      orderLabel: this.props.sidebarOrderLabel,
      ascOrDesc: this.props.sidebarAscOrDesc,
    };
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { notes: prevNotes, sidebarOrderLabel: prevSidebarOrderLabel, sidebarAscOrDesc: prevSidebarAscOrDesc } = prevProps;
    const { notes, sidebarOrderLabel, sidebarAscOrDesc } = this.props;

    if (
      !isEqual(prevNotes, notes) ||
      prevSidebarOrderLabel !== sidebarOrderLabel ||
      prevSidebarAscOrDesc !== sidebarAscOrDesc
    ) {
      this.setState(
        producer(this.state, draft => {
          draft.orderedNotes = orderBy(
            notes,
            sidebarOrderLabel,
            sidebarAscOrDesc as any
          );
          // draft.orderLabel = sidebarOrderLabel;
          // draft.ascOrDesc = sidebarAscOrDesc;
          // return;
        })
      );
    }
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  public render() {
    return (
      <Fragment>
        {this.renderSidebar()}
      </Fragment>
    );
  }

  private renderSidebar() {
    const { orderedNotes, orderLabel, ascOrDesc } = this.state;
    const { category, updateSidebarCategory, notes, sidebarAscOrDesc, sidebarOrderLabel } = this.props;
    return (
      <SidebarView sidebarShow={this.state.sidebarShow}>
        <Button
          //@ts-ignore: custom bootstrap class
          variant="transparent"
          onClick={(e: React.MouseEvent) => {
            this.toggleSidebar(e);
          }}
          onMouseDown={e => e.preventDefault()}
          style={{
            padding: '2px',
            width: 'fit-content',
            margin: '13px 6px',
            position: 'absolute',
            right: '-38px',
            zIndex: 1000,
          }}
        >
          <Icon.Sidebar size={20} color="#848484" />
        </Button>
        <Top sidebarShow={this.state.sidebarShow}>
          <SidebarHeader>
            <Dropdown>
              <Dropdown.Toggle
                // @ts-ignore
                variant="transparent"
              >
                <span style={{ fontSize: '15px' }}>{category}</span>
                <Icon.ChevronDown size={20} style={{ marginTop: '.2em' }} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    updateSidebarCategory('All');
                  }}
                  onMouseDown={(e: any) => e.preventDefault()}
                >
                  All
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    updateSidebarCategory('Trash');
                  }}
                  onMouseDown={(e: any) => e.preventDefault()}
                >
                  Trash
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Button
              //@ts-ignore: custom bootstrap class
              variant="transparent"
              onClick={this.handleSubmit}
              style={{ padding: '4px 4px 0px', width: 'fit-content' }}
            >
              <img src={newNoteIcon} alt="newNoteIcon" width="26" height="26" />
            </Button>
          </SidebarHeader>
          <NoteOrder>
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id={`tooltip-ascOrDesc`}>
                  {sidebarAscOrDesc === 'asc' ? 'Oldest first' : 'Most recent first'}
                </Tooltip>
              }
            >
              <Button
                //@ts-ignore
                variant="transparent"
                onClick={() => {
                  this.toggleAscOrDesc();
                }}
                onMouseDown={(e: any) => e.preventDefault()}
              >
                {sidebarAscOrDesc === 'asc' ? (
                  <Icon.ChevronDown size={14} style={{ marginTop: '.1em' }} />
                ) : (
                  <Icon.ChevronUp size={14} style={{ marginTop: '.1em' }} />
                )}
              </Button>
            </OverlayTrigger>
            <Button
              //@ts-ignore
              variant="transparent"
              onClick={() => {
                this.toggleOrderLabel();
              }}
              onMouseDown={(e: any) => e.preventDefault()}
              style={{ fontSize: '11px' }}
            >
              {sidebarOrderLabel === 'updatedAt' ? 'Updated At' : 'Created At'}
            </Button>
          </NoteOrder>
          <NoteItemList>
            {orderedNotes &&
              orderedNotes.map((note, i) => {
                const firstSectionId = note.sectionsOrder[0];
                const firstLeftModuleId = note.sections[firstSectionId].leftModulesOrder[0];
                const leftModule = note.sections[firstSectionId].leftModules[firstLeftModuleId];
                if (
                  this.props.category === 'All' &&
                  !note.inTrash &&
                  !note.default &&
                  //FUTURE: fix why 'welcome' note still exists after new user sign up
                  note.noteId !== 'welcome' &&
                  note.noteId !== 'algorithmsExample' &&
                  note.noteId !== 'exampleNote2' &&
                  note.noteId !== 'homeNote'
                ) {
                  return (
                    <NoteItem to={`/notes/${note.noteId}`} key={i}>
                      <NoteItemDescription type="title">
                        {note.title}
                      </NoteItemDescription>
                      <NoteItemDescription>
                        {leftModule.payloadObj.markdown}
                      </NoteItemDescription>
                      <NoteItemDescription>
                        {moment(note.updatedAt.toMillis()).format(
                          'MMM. Do, YYYY'
                        )}
                      </NoteItemDescription>
                    </NoteItem>
                  );
                } else if (
                  this.props.category === 'Trash' &&
                  note.inTrash &&
                  !note.default &&
                  //FUTURE: fix why 'welcome' note still exists after new user sign up
                  note.noteId !== 'welcome' &&
                  note.noteId !== 'algorithmsExample' &&
                  note.noteId !== 'exampleNote2' &&
                  note.noteId !== 'homeNote'
                ) {
                  return (
                    <NoteItem to={`/notes/${note.noteId}`} key={i}>
                      <NoteItemDescription type="title">
                        {note.title}
                      </NoteItemDescription>
                      <NoteItemDescription>
                        {leftModule.payloadObj.markdown}
                      </NoteItemDescription>
                      <NoteItemDescription>
                        {moment(note.updatedAt.toMillis()).format(
                          'MMM. Do, YYYY'
                        )}
                      </NoteItemDescription>
                    </NoteItem>
                  );
                }
              })}
          </NoteItemList>
        </Top>
        <Bottom sidebarShow={this.state.sidebarShow}>
          {/*
        //@ts-ignore */}
          <Dropdown
            className="dropdown-button"
            style={{ width: '100%', display: 'flex' }}
          >
            <Dropdown.Toggle
              //@ts-ignore
              variant="transparent"
              style={{}}
            >
              <span
                style={{
                  textAlign: 'left',
                  width: '165px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {this.props.userEmail}
              </span>
              <Icon.Settings size={20} color="#848484" />
            </Dropdown.Toggle>
            {/*
          //@ts-ignore */}
            <Dropdown.Menu alignRight>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ showSettingsModal: true });
                }}
              >
                Settings
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ showFeedbackModal: true });
                }}
              >
                Feedback
              </Dropdown.Item>
              <Dropdown.Item onClick={this.props.signOut}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <CenteredModal
            show={this.state.showSettingsModal}
            onHide={this.closeModal}
            userEmail={this.props.userEmail}
          />
          <FeedbackModal
            show={this.state.showFeedbackModal}
            onHide={this.closeModal}
          />
        </Bottom>
      </SidebarView>
    );
  }

  private handleSubmit = (e: any) => {
    e.preventDefault();
    createNoteTemplate(this.props.userEmail);
    this.props.createNote(createNoteTemplate(this.props.userEmail));
  };

  private closeModal = () => {
    this.setState({ showSettingsModal: false, showFeedbackModal: false });
  };

  private toggleOrderLabel = () => {
    const { orderLabel } = this.state;
    const { toggleSidebarOrderLabel } = this.props;
    toggleSidebarOrderLabel();
    // if (orderLabel === 'updatedAt') {
    //   this.setState({
    //     orderLabel: 'createdAt',
    //   });
    // } else {
    //   this.setState({
    //     orderLabel: 'updatedAt',
    //   });
    // }
  };

  private toggleAscOrDesc = () => {
    const { ascOrDesc } = this.state;
    const { toggleSidebarAscOrDesc } = this.props;
    toggleSidebarAscOrDesc();
    // if (ascOrDesc === 'asc') {
    //   this.setState({
    //     ascOrDesc: 'desc',
    //   });
    // } else {
    //   this.setState({
    //     ascOrDesc: 'asc',
    //   });
    // }
  };

  private toggleSidebar = (e: React.MouseEvent) => {
    e.preventDefault();
    const currentSidebarShowState = this.state.sidebarShow;
    this.setState({
      sidebarShow: !currentSidebarShowState,
    });
  };

  private handleResize = () => {
    if (window.innerWidth < 769) {
      this.setState({
        sidebarShow: false,
      });
    } else if (window.innerWidth >= 769) {
      this.setState({
        sidebarShow: true,
      });
    }
  };
}

export { Sidebar };

interface ISidebarViewProps {
  sidebarShow: boolean;
}

const SidebarView = styled.nav<ISidebarViewProps>`
  transform: ${props =>
    props.sidebarShow ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)'};
  width: ${props => (props.sidebarShow ? '225px' : '0px')};
  min-width: ${props => (props.sidebarShow ? '225px' : '0px')};
  max-width: ${props => (props.sidebarShow ? '225px' : '0px')};
  transition: all 0.25s;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fffcf5;
  justify-content: space-between;
  box-shadow: inset 2px 0px 25px -3px rgba(0, 0, 0, 0.12);
  z-index: 1000;
  /* overflow-x: hidden;
  overflow-y: auto; */
  //allows both columns to span the full height of the browser window
  height: 100vh;
  &:after {
    visibility: hidden;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Top = styled.div<ISidebarViewProps>`
  visibility: ${props => (props.sidebarShow ? 'visible' : 'hidden')};
  opacity: ${props => (props.sidebarShow ? '1' : '0')};
  display: flex;
  transition: all 0.25s;
  flex-direction: column;
`;

const Bottom = styled.div<ISidebarViewProps>`
  visibility: ${props => (props.sidebarShow ? 'visible' : 'hidden')};
  opacity: ${props => (props.sidebarShow ? '1' : '0')};
  display: flex;
  transition: all 0.25s;
  padding: 8px 10px;
  align-items: center;
  justify-content: space-between;
  color: #848484;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const SidebarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 6px 15px 8px;
  font-size: 1.5em;
`;

const NoteOrder = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 12px;
`;

const NoteItemList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  border-top: solid 1px #c4c4c4;
`;

const NoteItem = styled(NavLink)`
  display: flex;
  flex-direction: column;
  min-height: fit-content;
  height: fit-content;
  padding: 15px 10px 10px;
  width: 205px;
  border-bottom: solid 1px #c4c4c4;
  color: #000;
  text-overflow: ellipsis;
  &.active {
    color: #000;
    background-color: rgba(233, 230, 237, 1);
  }
  &:focus {
    outline: none;
    background-color: rgba(233, 230, 237, 0.6);
  }
`;

interface INoteItemDescription {
  type?: string;
}

const NoteItemDescription = styled.div<INoteItemDescription>`
  color: ${props => (props.type === 'title' ? 'inherit' : '#8c8c8c')};
  font-size: ${props => (props.type === 'title' ? 'inherit' : '12px')};
  overflow: hidden;
  text-overflow: ellipsis;
  //to be able to clip the excerpt and add the ellipsis if overflow
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-height: ${props => (props.type === 'title' ? '18px' : '16px')};
  //max hieght is 2x line height
  max-height: ${props => (props.type === 'title' ? '36px' : '32px')};
  min-height: ${props => (props.type === 'title' ? '18px' : '16px')};
  margin-bottom: ${props => (props.type === 'title' ? '6px' : '5px')};
`;
