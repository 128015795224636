import { IModule } from "../data-models";

export const blankCodeEditor:IModule = {
  id: 'module-1',
  type: 'code',
  payloadObj: {
    code: `//new code block`,
    settings: {
      languageLabel: 'Javascript',
      wrapCode: true,
      theme: '',
      showLineNumbers: true,
      readOnly: false,
      tabSize: 2,
      fontSize: 14,
      enableLiveAutocompletion: false,
      linterOn: false,
    },
  },
  moduleAddress: {
    noteId: '',
    sectionId: '',
    rightModulesId: '',
  },
};
