import React, { Component, Fragment } from 'react';
import { styled } from '../../common/ThemedStyledComponent';
//@ts-ignore;
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

// import { ISectionsListProps as IProps } from './SectionsListContainer';
import { IModuleAddress, ISections } from '../../../data-models';
import { SectionContainer } from './SectionContainer';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';

interface IState {}

interface IProps {
  urlNoteId: string;
  sections: ISections;
  sectionsOrder: string[];
  updateSectionOrder(noteId: string, oldIndex: number, newIndex: number): void;
}

const SortableContainerWrapper = SortableContainer(
  ({ children }: { children: any }) => {
    return <SectionsListView>{children}</SectionsListView>;
  }
);

const SectionsList = (props: IProps) => {

  const renderSections = () => {
    const { urlNoteId, sections, sectionsOrder } = props;

    return sectionsOrder.map((sectionId, index) => {
      const moduleAddress: IModuleAddress = {
        noteId: urlNoteId,
        sectionId,
      };
      return (
        <SectionContainer
          key={sectionId}
          index={index}
          moduleAddress={moduleAddress}
          section={sections[sectionId]}
          sectionsLength={sectionsOrder.length}
        />
      );
    });
  };

  const onDragEnd = (result: { oldIndex: number; newIndex: number }) => {
    const { oldIndex, newIndex } = result;
    const { urlNoteId, updateSectionOrder } = props;
    if (newIndex === null) {
      return;
    }
    if (oldIndex === newIndex) {
      return;
    }
    updateSectionOrder(urlNoteId, oldIndex, newIndex);
    return;
  };

  return (
    <SortableContainerWrapper
      onSortEnd={onDragEnd}
      useDragHandle
      lockAxis={'y'}
      helperClass={'sortableHelper'}
    >
      {renderSections()}
    </SortableContainerWrapper>
  );
};

export { SectionsList };

//style
const SectionsListView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  height: max-content;
  margin-bottom: 20px;
`;
