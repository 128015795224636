import React, { Component } from 'react';
//@ts-ignore
import Editor from '@brandontle/rich-markdown-editor';
import { debounce } from 'lodash';
import immer from 'immer';
//@ts-ignore
import MicrolinkCard from '@microlink/react';
//@ts-ignore

import { IRichEditorProps as IProps } from './RichEditorContainer';
import { IModuleAddress } from '../../../data-models';
import { styled } from '../../common/ThemedStyledComponent';
import { Value } from 'slate';

interface IComponentProps extends IProps {
  updateModuleInStore(
    moduleAddress: IModuleAddress,
    payloadObj: { markdown: string }
  ): void;
}

interface IState {
  value: string;
  dark: boolean;
  readOnly: boolean;
  firstRender?: boolean;
}

class RichEditor extends Component<IComponentProps, IState> {
  private updateModuleInStore = debounce(this.props.updateModuleInStore, 500);

  constructor(props: IProps) {
    super(props);
    this.state = {
      value: this.props.payloadObj.markdown,
      dark: false,
      readOnly: false,
      firstRender: true,
    };
    //debounce updateModuleInStore function to .5seconds
    // this.updateModuleInStore = debounce(this.props.updateModuleInStore, 500);
  }

  //updates component's state on any change (value and selection)
  //and updates redux store IFF content value changes
  private handleChange = (value: string, documentChanged: boolean) => {
    // Guard clause bc RichEditor triggers onChange upon first render
    if (this.state.firstRender) {
      this.setState({
        firstRender: false,
      });
      return;
    }

    if (documentChanged) {

      const { moduleAddress, payloadObj } = this.props;
      const updatedPayloadObj = immer(payloadObj, draft => {
        draft.markdown = value;
        return;
      });

      this.updateModuleInStore(moduleAddress, updatedPayloadObj);
    }
    this.setState({
      value,
    });
  };

  public componentWillUnmount() {
    //flush debouncing on unmount
    this.updateModuleInStore.flush();
    this.setState({
      value: '',
    });
  }

  public render() {
    const { value, dark, readOnly } = this.state;
    return (
      <StyledEditor
        id="example"
        readOnly={readOnly}
        defaultValue={value}
        // onSave={(options: any) => console.log('Save triggered', options)}
        // onCancel={() => console.log('Cancel triggered')}
        onChange={(func: any, documentChanged: boolean) => {
          this.handleChange(func(), documentChanged);
        }}
        // onClickLink={(href: string) => {
        //   window.open(href);
        // }}
        // onClickLink={(href: string) => {
        //   if (isInternalLink(href)) {
        //     history.push(href);
        //   } else {
        //     window.location.href = href;
        //   }
        // }}
        // onSearchLink={async (term: any) => {
        //   console.log('Searched link: ', term);
        //   return [
        //     {
        //       title: term,
        //       url: 'localhost',
        //     },
        //   ];
        // }}
        // uploadImage={(file: any) => {
        //   console.log('File upload triggered: ', file);

        //   // Delay to simulate time taken to upload
        //   return new Promise(resolve => {
        //     setTimeout(() => resolve('http://lorempixel.com/400/200/'), 1500);
        //   });
        // }}
        // FUTURE: fix
        // getLinkComponent={(node:any) => {
        //   console.log('link', node);
        //   console.log('link text', node.text);
        //   return (<MicrolinkCard url={'https://github.com/brandontle/rich-markdown-editor/blob/master/example/src/index.js'}/>);
        // }}

        // getLinkComponent={node => {
        //   if (node.data.get("href").match(/google/)) {
        //     return GoogleEmbed;
        //   }
        // }}
        dark={dark}
      />
    );
  }
}

export { RichEditor };

const StyledEditor = styled(Editor)`
  justify-content: unset;
  .keiPyl {
    height: 100%;
    background: transparent;
  }
  a {
    color: darkgoldenrod;
    text-decoration: underline;
  }
`;
