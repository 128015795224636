import React, { Component } from 'react';
//@ts-ignore
import Iframe from 'react-iframe';
import { LinkInputContainer } from './LinkInputContainer';
import { IModuleAddress } from '../../../data-models';
import ReactPlayer from 'react-player';
//@ts-ignore
import MicrolinkCard from '@microlink/react';
import { styled } from '../../common/ThemedStyledComponent';
//@ts-ignore
import ModalImage from 'react-modal-image';

interface IState {
  isLoaded: boolean;
}
interface IProps {
  moduleAddress: IModuleAddress;
  payloadObj: {
    link: string;
    linkType: string;
  };
}

class Frame extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  public render() {
    const { link, linkType } = this.props.payloadObj;

    if (link && linkType === 'Embed') {
      return (
        <Iframe
          url={link}
          width="100%"
          height="450px"
          className="myClassname"
          display="block"
          position="relative"
          onLoad={() => {
            this.isLoaded(true);
          }}
          allowFullScreen
          // style={{ borderRadius: '3px' }}
        />
      );
    } else if (link && linkType === 'Video') {
      return (
        <ReactPlayer
          url={link}
          width="100%"
          height="fit-content"
          controls={true}
        />
      );
    } else if (link && linkType === 'Audio') {
      return (
        <ReactPlayer
          url={link}
          width="100%"
          height="fit-content"
          controls={true}
        />
      );
    } else if(link && linkType === 'Preview') {
      return (
        <StyledMicrolinkCard data-testid='microLink-card' url={link}/>
      )
    } else if(link && linkType === 'Image') {
      return (
        <StyledModalImage small={link} medium={link} hideZoom={true}/>
      )
    } else {
      return (
        <LinkInputContainer
          value={link}
          moduleAddress={this.props.moduleAddress}
          payloadObj={this.props.payloadObj}
        />
      );
    }
  }

  private isLoaded(status: boolean) {
    this.setState({
      isLoaded: status,
    });
  }

  //FUTURE: add URL validation and warning text
  private validURL(str: string) {
    var regexQuery =
      '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$';
    var url = new RegExp(regexQuery, 'i');
    return url.test(str);
  }
}

export { Frame };

const StyledMicrolinkCard = styled(MicrolinkCard)`
  display: flex;
  flex: 1 1;
  border-radius: 6px;
`

const StyledModalImage = styled(ModalImage)`
  border-radius: 6px;
  margin-bottom: -7px;
`
