import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { INote } from '../data-models';

const firestore = firebase.firestore;

export const defaultNewNote:INote = {
  noteId: 'default',
  ownerIds: [''],
  title: 'New Note',
  inTrash: false,
  default: true,
  createdAt: {} as firebase.firestore.Timestamp,
  updatedAt: {} as firebase.firestore.Timestamp,
  sectionsOrder: ['section-1'],
  sections: {
    'section-1': {
      id: 'section-1',
      leftModulesOrder: ['module-1'],
      rightModulesOrder: ['module-1'],
      leftModules: {
        'module-1': {
          id: 'module-1',
          type: 'markdown',
          payloadObj: {
            markdown: ``,
          },
          moduleAddress: {
            noteId: '',
            sectionId: '',
            leftModulesId: ''
          }
        },
      },
      rightModules: {
        'module-1': {
          id: 'module-1',
          type: 'code',
          payloadObj: {
            code: `//new code block`,
            settings: {
              languageLabel: 'Javascript',
              wrapCode: true,
              theme: '',
              showLineNumbers: true,
              readOnly: false,
              tabSize: 2,
              fontSize: 14,
              enableLiveAutocompletion: false,
              linterOn: false,
            },
          },
          moduleAddress: {
            noteId: '',
            sectionId: '',
            rightModulesId: ''
          }
        },
      },
    },
  },
}
