import React, { Component, Fragment } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { ILinkInputProps as IProps } from './LinkInputContainer';
import { styled } from '../../common/ThemedStyledComponent';

interface IState {
  linkType: string;
  link: string;
}

class LinkInput extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      linkType: '',
      link: '',
    };
  }

  public componentDidMount() {
    this.setState({
      linkType: this.props.payloadObj.linkType || 'Image',
      link: this.props.payloadObj.link || '',
    });
  }

  public render() {
    const { linkType } = this.state;
    return (
      <LinkInputWrapper>
        <span style={{fontSize: '12px', color: '#7b8182'}}>{linkType}:</span>
        <InputGroup
          size="sm"
          className="mb-3"
          style={{ display: 'flex', width: 'unset' }}
        >
          {/* <Dropdown
            as={InputGroup.Append}
            variant="outline-secondary"
            id="input-group-dropdown-2"
            onSelect={(eventKey: string) => {
              this.handleDropdownSelect(eventKey);
            }}
          >
            <Dropdown.Toggle
              variant="outline-secondary"
              id="dropdown-basic"
              style={{ padding: '2.3px', borderRadius: '3px 0px 0px 3px' }}
            >
              {this.state.linkType}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                style={{ borderRadius: '3px 0px 0px 3px' }}
                //capitalized to simplify linkType state
                eventKey="Embed"
              >
                Embed
              </Dropdown.Item>
              <Dropdown.Item
                style={{ borderRadius: '3px 0px 0px 3px' }}
                eventKey="Image"
              >
                Image
              </Dropdown.Item>
              <Dropdown.Item
                style={{ borderRadius: '3px 0px 0px 3px' }}
                eventKey="Video"
              >
                Video
              </Dropdown.Item>
              <Dropdown.Item
                style={{ borderRadius: '3px 0px 0px 3px' }}
                eventKey="Video"
              >
                Audio
              </Dropdown.Item>
              <Dropdown.Item
                style={{ borderRadius: '3px 0px 0px 3px' }}
                eventKey="Preview"
              >
                Preview
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}

          <Form.Control
            placeholder={`Paste link to ${linkType.toLowerCase()} here`}
            aria-label={`Paste link to ${linkType.toLowerCase()} here`}
            aria-describedby="basic-addon2"
            value={this.state.link}
            onChange={(event: any) => this.handleChange(event)}
          />

          <InputGroup.Append>
            <Button
              variant="outline-secondary"
              onClick={(event: any) => {
                this.handleSubmit(event);
              }}
            >
              Submit
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </LinkInputWrapper>
    );
  }

  private handleChange = (event: React.FormEvent<EventTarget>) => {
    let target = event.target as HTMLInputElement;
    this.setState({
      link: target.value,
    });
  };

  private handleDropdownSelect = (eventKey: string) => {
    this.setState({
      linkType: eventKey,
    });
  };

  private handleSubmit = (event: any) => {
    event.preventDefault();
    const { link, linkType } = this.state;

    const { updateModuleInStore, moduleAddress, payloadObj } = this.props;
    //update this:
    const updatedPayloadObj = { link, linkType };
    updateModuleInStore(moduleAddress, updatedPayloadObj);
  };
}

export { LinkInput };

const LinkInputWrapper = styled.div`
  padding: 14px 8px 10px;
  background-color: transparent;
`;
