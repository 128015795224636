import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IAppState } from '../../../store/reducers/index';
import { Section } from './Section';
import { IModuleAddress, ISection } from '../../../data-models';
import { insertSection, deleteSection } from '../../../store/actions';

interface IStateToProps {}
interface IDispatchToProps {
  insertSection(
    noteId?: string,
    sectionIndex?: number,
    sectionToInsert?: ISection
  ): void;
  deleteSection(noteId?: string, sectionIndex?: number): void;
}
interface IOwnProps {
  moduleAddress: IModuleAddress;
  section: ISection;
  sectionsLength: number;
  index: number;
}

export interface ISectionProps
  extends IStateToProps,
    IDispatchToProps,
    IOwnProps {}

function mapStateToProps(
  state: IAppState,
  ownProps: IOwnProps
): IStateToProps & IOwnProps {
  const { moduleAddress, section, sectionsLength, index } = ownProps;
  return {
    moduleAddress,
    section,
    sectionsLength,
    index,
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>): any {
  return {
    insertSection: bindActionCreators(insertSection, dispatch),
    deleteSection: bindActionCreators(deleteSection, dispatch),
  };
}

export const SectionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Section) as any;
