import { IModule } from "../data-models";

export const newLink:IModule = {
  id: 'module-1',
  type: 'link',
  payloadObj: {
    link: ``,
    linkType: ``
  },
  moduleAddress: {
    noteId: '',
    sectionId: '',
    rightModulesId: ''
  }
}

export const createNewLink = (linkType:string) => {
  return {
    id: Math.random().toString(36).substr(2, 9),
    type: 'link',
    payloadObj: {
      link: ``,
      linkType: linkType
    },
    moduleAddress: {
      noteId: '',
      sectionId: '',
      rightModulesId: ''
    }
  }
}
