import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import * as Icon from 'react-feather';
import { styled } from '../common/ThemedStyledComponent';

interface IProps {
  onSelect(eventKey: string): void;
  sectionsLength: number;
}

const SectionSettingsButton = (props: IProps) => {
  return (
    <StyledSectionSettingWrapper
      id="input-group-dropdown-2"
      variant="transparent"
      onSelect={(eventKey: string) => {
        props.onSelect(eventKey);
      }}
      onMouseDown={(e: any) => e.preventDefault()}
    >
      <Dropdown.Toggle
        //@ts-ignore
        variant="transparent"
        id="dropdown-basic"
      >
        {/* {`::`} */}
        <Icon.Settings size={18} color="#848484" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item eventKey="duplicate">Duplicate Section</Dropdown.Item>
        {props.sectionsLength > 1 ? (
          <Dropdown.Item eventKey="delete">Delete Section</Dropdown.Item>
        ) : null}
      </Dropdown.Menu>
    </StyledSectionSettingWrapper>
  );
};

export { SectionSettingsButton };

const StyledSectionSettingWrapper = styled(Dropdown)`
  /* margin-left: -28px; */
  display: flex;
  transition: all 0.4s;
`;
