import firebase from 'firebase/app';
import 'firebase/firestore';
import { INote } from '../data-models';

const firestore = firebase.firestore;

export const welcomeNote:INote = {
  noteId: 'welcome',
  ownerIds: [''],
  title: 'Welcome 👋',
  inTrash: false,
  default: true,
  createdAt: firebase.firestore.Timestamp.now(),
  updatedAt: firebase.firestore.Timestamp.now(),
  sectionsOrder: ['section-1', 'section-2', 'section-4'],
  sections: {
    'section-1': {
      id: 'section-1',
      leftModulesOrder: ['module-1'],
      rightModulesOrder: ['module-1', 'module-2'],
      leftModules: {
        'module-1': {
          id: 'module-1',
          type: 'markdown',
          payloadObj: {
            markdown: `Each note is made out of rearrangeable **sections**. To add a new section, delete an existing one, or reorder them, use the buttons found on the left that reveal on hover!
            \nEach new section has a **description** on the left for your primary text notes. And to the right is an area for any reference **media** you want for each description!
            \nFor example, try submitting the image link to the right! ➡️`,
          },
          moduleAddress: {
            noteId: '',
            sectionId: '',
            leftModulesId: '',
          },
        },
      },
      rightModules: {
        'module-1': {
          id: 'module-1',
          type: 'code',
          payloadObj: {
            code: `console.log('helloooo world!');`,
            settings: {
              languageLabel: 'Javascript',
              wrapCode: true,
              theme: '',
              showLineNumbers: true,
              readOnly: false,
              tabSize: 2,
              fontSize: 14,
              enableLiveAutocompletion: false,
              linterOn: false,
            },
          },
          moduleAddress: {
            noteId: '',
            sectionId: '',
            rightModulesId: '',
          },
        },
        'module-2': {
          id: 'module-2',
          type: 'link',
          payloadObj: {
            link: `https://i.imgur.com/NXZbSkU.jpg`,
            linkType: ``,
          },
          moduleAddress: {
            noteId: '',
            sectionId: '',
            rightModulesId: '',
          },
        },
      },
    },
    'section-2': {
      id: 'section-2',
      leftModulesOrder: ['module-1'],
      rightModulesOrder: ['module-1', 'module-2'],
      leftModules: {
        'module-1': {
          id: 'module-1',
          type: 'markdown',
          payloadObj: {
            markdown: `## Useful Tips\n### Links
            \nYou can also add videos and audio clips from sites like Youtube, Vimeo, Facebook, Twitter, or Soundcloud.
            \nOr you can simply just reference a link with a preview.`,
          },
          moduleAddress: {
            noteId: '',
            sectionId: '',
            leftModulesId: '',
          },
        },
      },
      rightModules: {
        'module-1': {
          id: 'module-1',
          type: 'link',
          payloadObj: {
            link: `https://www.youtube.com/watch?v=mxK8b99iJTg`,
            linkType: `Video`,
          },
          moduleAddress: {
            noteId: '',
            sectionId: '',
            rightModulesId: '',
          },
        },
        'module-2': {
          id: 'module-2',
          type: 'link',
          payloadObj: {
            link: `https://reactjs.org/docs/hooks-overview.html#state-hook`,
            linkType: `Preview`,
          },
          moduleAddress: {
            noteId: '',
            sectionId: '',
            rightModulesId: '',
          },
        },
      },
    },
    // 'section-3': {
    //   id: 'section-2',
    //   leftModulesOrder: ['module-1'],
    //   rightModulesOrder: [],
    //   leftModules: {
    //     'module-1': {
    //       id: 'module-1',
    //       type: 'markdown',
    //       payloadObj: {
    //         markdown: `### List Boards\nList boards can also be added to organize lists such as tasks or reminders.
    //         \n\nEach list item has a title, label, and a description. You can even rearrange items by dragging and dropping them within each list board.
    //         \n\nTry adding a List Board on the right ➡️`,
    //       },
    //       moduleAddress: {
    //         noteId: '',
    //         sectionId: '',
    //         leftModulesId: '',
    //       },
    //     },
    //   },
    //   rightModules: {
    //     // 'module-1': {
    //     //   id:'module-1',
    //     //   type: 'List Board',
    //     //   payloadObj: {
    //     //     listBoardData: {
    //     //       lanes: [
    //     //         {
    //     //           cards: [
    //     //             {
    //     //               id: 'f1e91770-3c61-11e9-b093-bd3926e36127',
    //     //               laneId: 'lane1',
    //     //               title: 'Add new item to list board',
    //     //               label: 'Due',
    //     //               description: 'Use the button below!',
    //     //             },
    //     //           ],
    //     //           currentPage: 1,
    //     //           id: 'lane1',
    //     //           label: '',
    //     //           title: 'List Board',
    //     //         },
    //     //       ],
    //     //     },
    //     //   },
    //     //   moduleAddress: {
    //     //     noteId: '',
    //     //     sectionId: '',
    //     //     rightModulesId: '',
    //     //   },
    //     // },
    //   },
    // },
    'section-4': {
      id: 'section-4',
      leftModulesOrder: ['module-1'],
      rightModulesOrder: ['module-1'],
      leftModules: {
        'module-1': {
          id: 'module-1',
          type: 'markdown',
          payloadObj: {
            markdown: `### Markdown\nA few Markdown styles this editor is capable of include not only # headers, but also:
            \n* **bold** : double-wrapped *
            \n* *italics* : single-wrapped *
            \n* \`Code does work here too\`, but it's better over there! ➡️
            \n* You can also highlight texts to change their styles!
            \n**Try it out!** 😎`,
          },
          moduleAddress: {
            noteId: '',
            sectionId: '',
            leftModulesId: '',
          },
        },
      },
      rightModules: {
        'module-1': {
          id: 'module-1',
          type: 'code',
          payloadObj: {
            code: `//another new block\nconst currentlanguageLabel = 'javascript';\n//use the selector below to change the programming languageLabel!`,
            settings: {
              languageLabel: 'Javascript',
              wrapCode: true,
              theme: '',
              showLineNumbers: true,
              readOnly: false,
              tabSize: 2,
              fontSize: 14,
              enableLiveAutocompletion: false,
              linterOn: false,
            },
          },
          moduleAddress: {
            noteId: '',
            sectionId: '',
            rightModulesId: '',
          },
        },
      },
    },
  },
};
