import React, { Component, Fragment } from 'react';
import { styled } from '../../common/ThemedStyledComponent';
import immer from 'immer';
import { CodeEditorContainer } from '../../contentModules/CodeEditor/codeEditorContainer';
import { IRightProps as IProps } from './RightContainer';
import { Frame } from '../../contentModules/LinkInput/Frame';
import { ModuleWrapper } from '../../common/ModuleWrapper';
import { InsertModuleButton } from '../../common/InsertModuleButton';
import { IModuleAddress } from '../../../data-models';
import { ListBoard } from '../../contentModules/ListBoard';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import dragHandleIcon from './dragHandleIcon.svg';

interface IState {}

const DragHandle = SortableHandle(() => (
  <StyledDragHandle>
    <img src={dragHandleIcon} width="24" height="24" />
  </StyledDragHandle>
));

const SortableItem = SortableElement(({ children }: { children: any }) => (
  <ModuleView>
    <DragHandle />
    {children}
  </ModuleView>
));

const SortableContainerWrapper = SortableContainer(
  ({ children }: { children: any }) => {
    return (
      <StyledSortableContainerWrapper>
        {children}
      </StyledSortableContainerWrapper>
    );
  }
);

class Right extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
    this.handleOptionsClick = this.handleOptionsClick.bind(this);
  }

  public render() {
    const { noteId, sectionId } = this.props.moduleAddress;
    return (
      <RightView>
        <InsertModuleButton
          insertModule={this.props.insertModule}
          moduleAddress={{ noteId, sectionId }}
          moduleIndex={-1}
        />
        <SortableContainerWrapper
          onSortEnd={this.onDragEnd}
          useDragHandle
          lockAxis={'y'}
          lockToContainerEdges
          lockOffset={'0%'}
        >
          {this.renderRightContent()}
        </SortableContainerWrapper>
      </RightView>
    );
  }
  private renderRightContent() {
    //map over rightContent array and return (for example) the CodeEditor with the code module
    const {
      rightModules,
      rightModulesOrder,
      insertModule,
      updateModuleInStore,
    } = this.props;
    //FUTURE: remove rightContent safety check?
    return (
      rightModulesOrder.length > 0 &&
      rightModulesOrder.map((rightModulesId, index) => {
        //use immer to create new immutable moduleAddress with leftIndex injected
        const moduleAddress = immer(this.props.moduleAddress, draft => {
          draft.rightModulesId = rightModulesId;
        });
        const { type, payloadObj } = rightModules[rightModulesId];
        //conditional in case for other types of module in the future
        if (type === 'code') {
          return (
            <Fragment key={rightModulesId}>
              <SortableItem key={rightModulesId} index={index}>
                <ModuleWrapper
                  index={index}
                  handleDeleteModuleClick={this.handleDeleteModuleClick}
                  handleOptionsClick={this.handleOptionsClick}
                  payloadObj={payloadObj}
                  moduleAddress={moduleAddress}
                >
                  <CodeEditorContainer
                    payloadObj={payloadObj}
                    moduleAddress={moduleAddress}
                  />
                </ModuleWrapper>
                <InsertModuleButton
                  insertModule={insertModule}
                  moduleAddress={moduleAddress}
                  moduleIndex={index}
                />
              </SortableItem>
            </Fragment>
          );
        } else if (type === 'link') {
          return (
            <SortableItem key={`item-${index}`} index={index}>
              <ModuleWrapper
                index={index}
                handleDeleteModuleClick={this.handleDeleteModuleClick}
                handleOptionsClick={this.handleOptionsClick}
                payloadObj={payloadObj}
                moduleAddress={moduleAddress}
              >
                <Frame payloadObj={payloadObj} moduleAddress={moduleAddress} />
              </ModuleWrapper>
              <InsertModuleButton
                insertModule={insertModule}
                moduleAddress={moduleAddress}
                moduleIndex={index}
              />
            </SortableItem>
          );
        } else if (type === 'List Board') {
          return (
            <SortableItem key={`item-${index}`} index={index}>
              <ModuleWrapper
                index={index}
                handleDeleteModuleClick={this.handleDeleteModuleClick}
                handleOptionsClick={this.handleOptionsClick}
                payloadObj={payloadObj}
                moduleAddress={moduleAddress}
              >
                <ListBoard
                  data={payloadObj.listBoardData}
                  updateModuleInStore={updateModuleInStore}
                  moduleAddress={moduleAddress}
                />
              </ModuleWrapper>
              <InsertModuleButton
                insertModule={insertModule}
                moduleAddress={moduleAddress}
                moduleIndex={index}
              />
            </SortableItem>
          );
        }
      })
    );
  }

  private handleDeleteModuleClick = (moduleIndex: number) => {
    const { moduleAddress, deleteModule } = this.props;
    const { noteId, sectionId } = moduleAddress;
    deleteModule(noteId as string, sectionId as string, moduleIndex);
  };

  private onDragEnd = (result: { oldIndex: number; newIndex: number }) => {
    const { oldIndex, newIndex } = result;
    if (newIndex === null) {
      return;
    }
    if (oldIndex === newIndex) {
      return;
    }

    const { moduleAddress, updateModuleOrder } = this.props;
    const { noteId, sectionId } = moduleAddress;

    updateModuleOrder(
      noteId as string,
      sectionId as string,
      oldIndex,
      newIndex
    );
  };

  private handleOptionsClick = (
    moduleId: string,
    optionLabel: string,
    optionValue: any
  ) => {
    const { moduleAddress, updateModuleInStore, rightModules } = this.props;
    const { noteId, sectionId } = moduleAddress;
    const updatedContentAddress = {
      noteId,
      sectionId,
      rightModulesId: moduleId,
    } as IModuleAddress;
    const updatedPayloadObj: {} = immer(
      //FIX rightIndex and rightModulesOrder
      rightModules[moduleId].payloadObj,
      draft => {
        draft.settings[optionLabel] = optionValue;
        return;
      }
    );
    updateModuleInStore(updatedContentAddress, updatedPayloadObj);
  };
}

export { Right };

//styles

const StyledSortableContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RightView = styled.div`
  display: flex;
  max-width: 400px;
  height: fit-content;
  flex: 1 0.85 400px;
  flex-direction: column;
  font-size: 15px;
  padding-left: 31px;
  box-sizing: border-box;
  /* media queries */
  @media (max-width: 769px) {
    /* border-left: 2px solid rgba(0, 0, 0, 0.08);
    border-right: 2px solid rgba(0, 0, 0, 0.08); */
    padding: 0 31px;
    max-width: unset;
    width: 75%;
    flex: 1 0.85 auto;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledDragHandle = styled.div`
  position: relative;
  cursor: grab;
  right: 28px;
  top: 32px;
  opacity: 0;
`;

const ModuleView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: -20px;
  &:hover ${StyledDragHandle} {
    opacity: 1;
    transition: all 0.4s;
  }
`;
